import React from 'react';
import { Modal } from 'antd';
import { useModalStore } from '../store/core/modalStore';
import ModalUtil from '../store/core/modalUtil';


interface ModalContentProps {
    content: React.ReactNode;
    title?: string;
    width?: string;
    footer?: React.ReactNode;
}

const ModalComponent = () => {
    const { modals } = useModalStore();
    const onCloseButtonClick = (e: any) => {
        ModalUtil.closeModal(e, '');
    };

    return (
        <>
            {modals.map((modalContent: ModalContentProps, index: number) => {
                return (
                    <Modal
                        key={index}
                        open={true}
                        onCancel={onCloseButtonClick}
                        width={modalContent.width || 'auto'}
                        title={modalContent.title}
                        footer={modalContent.footer || null}
                        style={{
                            top: 20,

                        }}
                        styles={{
                            body: {
                                padding: '20px',
                                maxHeight: '90vh',
                                overflow: 'auto',
                            }
                        }}
                    >
                        <div>{modalContent.content}</div>
                    </Modal>
                );
            })}
        </>
    );
};

export { ModalComponent };
