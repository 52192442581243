import React, { useState, useEffect } from 'react';
import DataForm from '../DataForm/DataForm';
import useEventStore from '../../store/eventStore';
import { useAuthStore } from '../../store/authUserStore';
import { FormikValues } from 'formik';
import dayjs from 'dayjs';
import { EventModel } from '../../models/events/events';
const { v4: uuidv4 } = require('uuid');
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useUserStore from '../../store/userStore';
import { User, UserRole } from '../../models/users';
import useCountryStore from '../../store/countryStore';
import ModalUtil from '../../store/core/modalUtil';

interface EventFormModalProps {
  initialValues: EventModel | null;
}

const EventFormModal: React.FC<EventFormModalProps> = ({ initialValues }) => {
  const { createItem, updateItem, selectedItem, setSelectedItem, fetchItems, fetchItemById } = useEventStore();
  const { fetchItems: fetchCountries, items: countries } = useCountryStore();
  const { items: users, fetchItems: fetchUsers } = useUserStore();
  const { currentUser } = useAuthStore();
  const [isEditMode, setIsEditMode] = useState(false);
  const { t } = useTranslation();
  const [adminUsers, setAdminUsers] = useState<User[]>([]);

  useEffect(() => {
    setIsEditMode(!!initialValues);
  }, [initialValues]);

  useEffect(() => {
    fetchUsers();
    fetchCountries();
  }, [fetchUsers, fetchCountries]);

  useEffect(() => {
    if (users) {
      setAdminUsers(users.filter(user => user.role === UserRole.ADMIN));
    }
  }, [users]);

  const handleSubmit = async (values: FormikValues) => {
    if (!currentUser) throw new Error(t('events.userNotFound'));
    const eventData: EventModel = {
      id: isEditMode ? initialValues!.id : uuidv4(),
      name: values.name,
      description: values.description,
      startDate: values.startDate ? values.startDate.toISOString() : '',
      endDate: values.endDate ? values.endDate.toISOString() : '',
      userId: values.userId,
      standIds: [],
      status: initialValues?.status || 'pending',
      address: values.address,
      eventArea: values.eventArea,
      depositPrice: values.depositPrice,
      depositPriceDishes: values.depositPriceDishes,
      notes: values.notes,
    };

    if (isEditMode) {
      await updateItem(eventData.id!, eventData);
      setSelectedItem({ ...selectedItem, ...eventData });
      fetchItemById(eventData.id!)
    } else {
      await createItem(eventData);
    }

    ModalUtil.closeModal();
    fetchItems();
  };

  const defaultValues: FormikValues = {
    name: initialValues?.name || '',
    description: initialValues?.description || '',
    startDate: initialValues?.startDate ? dayjs(initialValues.startDate) : null,
    endDate: initialValues?.endDate ? dayjs(initialValues.endDate) : null,
    userId: initialValues?.userId || currentUser?.id!,
    address: {
      street: initialValues?.address?.street || '',
      city: initialValues?.address?.city || '',
      state: initialValues?.address?.state || '',
      postalCode: initialValues?.address?.postalCode || '',
      countryId: initialValues?.address?.countryId || ''
    },
    eventArea: initialValues?.eventArea || '',
    depositPrice: +(initialValues?.depositPrice || 0),
    depositPriceDishes: +(initialValues?.depositPriceDishes || 0),
    notes: initialValues?.notes || '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t('events.messageNotifier.nameRequired')),
    description: Yup.string(),
    startDate: Yup.date().required(t('events.messageNotifier.startDateRequired')),
    endDate: Yup.date().required(t('events.messageNotifier.endDateRequired')),
    userId: Yup.string().required(t('events.messageNotifier.userRequired')),
    address: Yup.object({
      street: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      postalCode: Yup.string(),
      countryId: Yup.string().required(t('general.required')),
    }),
    eventArea: Yup.string(),
    depositPrice: Yup.number().min(0),
    depositPriceDishes: Yup.number().min(0),
    notes: Yup.string(),
  });

  return <DataForm
    initialValues={defaultValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
    onCancel={ModalUtil.closeModal}
    numberOfColumns={2}
    fields={[
      { label: t('general.name'), name: 'name', type: 'text', placeholder: t('events.placeholderName') },
      { label: t('events.description'), name: 'description', type: 'textarea', placeholder: t('events.placeholderDescription') },
      { label: t('events.startDate'), name: 'startDate', type: 'date', placeholder: t('events.placeholderStartDate') },
      { label: t('events.endDate'), name: 'endDate', type: 'date', placeholder: t('events.placeholderEndDate') },
      {
        label: t('events.accountant'),
        name: 'userId',
        type: 'select',
        placeholder: t('events.accountant'),
        options: adminUsers.map((x) => {
          return { key: x.id, label: x.username, value: x.id };
        }),
      },
      { label: t('customer.street'), name: 'address.street', type: 'text', placeholder: t('customer.street') },
      { label: t('customer.city'), name: 'address.city', type: 'text', placeholder: t('customer.city') },
      { label: t('customer.state'), name: 'address.state', type: 'text', placeholder: t('customer.state') },
      { label: t('customer.postalCode'), name: 'address.postalCode', type: 'text', placeholder: t('customer.postalCode') },
      {
        label: t('customer.country'),
        name: 'address.countryId',
        type: 'select',
        placeholder: t('customer.country'),
        options: countries.map((x) => {
          return { key: x.id, label: x.name, value: x.id };
        }),
      }, { label: t('events.eventArea'), name: 'eventArea', type: 'text', placeholder: t('events.eventArea') },
      { label: t('events.depositPrice'), name: 'depositPrice', type: 'number', placeholder: t('events.depositPrice') },
      { label: t('events.depositPriceDishes'), name: 'depositPriceDishes', type: 'number', placeholder: t('events.depositPriceDishes') },
      { label: t('events.notes'), name: 'notes', type: 'textarea', placeholder: t('events.notes') },
    ]}
  />
};

export default EventFormModal;
