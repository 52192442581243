import React, { useEffect } from 'react';
import { Card, Button } from 'antd';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import EventFormModal from '../../components/Events/EventFormModal';
import useEventStore from '../../store/eventStore';
import { EventModel } from '../../models/events/events';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ModalUtil from '../../store/core/modalUtil';

const Events: React.FC = () => {
  const { items, fetchItems, deleteItem, setSelectedItem } = useEventStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const handleRowClick = (event: EventModel) => {
    navigate(`/events/details/${event.id}`);
  };

  const handleEdit = (eventId: string) => {
    const item = items.find((x) => x.id === eventId);
    if (item) {
      const newSelectedEvent = { ...item, userId: item.user?.id! };
      setSelectedItem({ ...item, userId: item.user?.id! });

      ModalUtil.openModal(<EventFormModal initialValues={newSelectedEvent} />, t('events.editEvent'), "50%");
    }
  }

  const columns: DataTableColumn<EventModel>[] = [
    { header: t('general.name'), accessor: 'name' },
    { header: t('events.description'), accessor: 'description' },
    { header: t('events.accountant'), accessor: 'user.username' },
    { header: t('events.startDate'), accessor: 'startDate', type: 'date' },
    { header: t('events.endDate'), accessor: 'endDate', type: 'date' },
    { header: t('events.status'), accessor: 'status', renderCell: (item) => t(`events.${item.status}`), hideFilter: true },
  ];

  return (
    <>
      <Card
        title={
          <>
            <span style={{ marginRight: '5px' }}>{t('events.title')}</span>
            <Button type="primary" onClick={() => {
              ModalUtil.openModal(<EventFormModal initialValues={null} />, t('events.createEvent'), "50%")
            }}>
              {t('events.createEvent')}
            </Button>
          </>
        }
      >
        <DataTable
          columns={columns}
          data={items}
          onDelete={deleteItem}
          onRowClick={handleRowClick}
          onEdit={handleEdit}
          enableFrontendFiltering
        />
      </Card>
    </>
  );
};

export default Events;
