import React, { useState, useEffect } from 'react';
import { FormikValues } from 'formik';
import useStandTypeStore from '../../store/standTypeStore';
import DataFormModal from '../DataForm/DataFormModal';
import DataForm from '../DataForm/DataForm';
import { StandType } from '../../models/standTypes/StandType';
import { StandTypeFormModalProps } from '../../models/standTypes/StandTypeFormModalProps';
const { v4: uuidv4 } = require('uuid');
import { useTranslation } from 'react-i18next';

const StandTypeFormModal: React.FC<StandTypeFormModalProps> = ({
  onClose,
  initialValues,
}) => {
  const { createItem, updateItem } = useStandTypeStore();
  const [isEditMode, setIsEditMode] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialValues) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [initialValues]);

  const handleSubmit = async (values: FormikValues, { setSubmitting, resetForm }: any) => {
    try {
      if (isEditMode && initialValues) {
        await updateItem(values.id!, {
          ...values,
        });
      } else {
        await createItem({
          id: uuidv4(),
          ...values,
        } as StandType);
      }
      resetForm();
      onClose();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <DataFormModal<StandType>
      initialValues={
        initialValues || {
          id: uuidv4(),
          name: '',
          notes: '',
        }
      }
      onSubmit={handleSubmit}
      fields={[
        { label: t('general.name'), name: 'name', type: 'text', placeholder: t('stand.placeholderStandTypeName') },
        {
          label: t('general.notes'),
          name: 'notes',
          type: 'textarea',
          placeholder: t('general.placeholderEnterNotes'),
        },
      ]}
      FormComponent={DataForm as any}
    />
  );
};

export default StandTypeFormModal;
