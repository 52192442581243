import React, { useEffect } from 'react';
import { Button, Card } from 'antd';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import ProductFormModal from '../../components/Products/ProductFormModal';
import { Product } from '../../models/products/products';
import { useNavigate } from 'react-router-dom';
import { useProductStore } from '../../store/productStore';
import { useTranslation } from 'react-i18next';
import ModalUtil from '../../store/core/modalUtil';

const Products: React.FC = () => {
  const { items: products, fetchItems, deleteItem, setSelectedItem } = useProductStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEdit = (productId: string) => {
    const item = products.find((x) => x.id === productId);
    setSelectedItem(item as any);

    ModalUtil.openModal(<ProductFormModal initialValues={item as any} />, item ? t('products.editProduct') : t('products.addProduct'), "500px");
  };

  const columns: DataTableColumn<Product>[] = [
    { header: t('products.name'), accessor: 'name' },
    { header: t('products.shortName'), accessor: 'shortName' },
    { header: t('products.type'), accessor: 'type.name' },
    { header: t('products.serviceCostPrice'), accessor: 'serviceCostPrice', type: 'currency' },
    { header: t('products.creditNotePrice'), accessor: 'creditNotePrice', type: 'currency' },
    { header: t('products.quantityPerBox'), accessor: 'quantityPerBox', type: 'number' },
    { header: t('products.notes'), accessor: 'notes' },
  ];

  const handleRowClick = (id: string) => {
    navigate(`/products/${id}`);
  };

  const handleCreateProduct = () => ModalUtil.openModal(<ProductFormModal initialValues={null} />, t('products.addProduct'), "500px");

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return (
    <>
      <Card
        title={
          <>
            <span style={{ marginRight: '5px' }}>{t('products.title')}</span>
            <Button type="primary" onClick={handleCreateProduct}>
              {t('products.createProduct')}
            </Button>
          </>
        }
      >
        <DataTable
          columns={columns}
          data={products}
          onDelete={deleteItem}
          onEdit={(product) => handleEdit(product)}
          onRowClick={(x) => handleRowClick(x.id!)}
          enableFrontendFiltering
        />
      </Card>
    </>
  );
};

export default Products;
