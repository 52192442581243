/* eslint-disable no-unused-vars */
import React from 'react';
import { FormikHelpers, FormikValues } from 'formik';
import ModalUtil from '../../store/core/modalUtil';

interface GenericModalProps<T> {
  initialValues: T;
  onSubmit: (values: FormikValues, actions: FormikHelpers<FormikValues>) => Promise<void>;
  fields: Array<{
    label: string;
    name: string;
    type: string;
    placeholder: string;
    component?: React.ElementType;
    options?: { value: string; label: string }[];
  }>;
  FormComponent: React.FC<{
    initialValues: T;
    onSubmit: (values: FormikValues, actions: FormikHelpers<FormikValues>) => Promise<void>;
    onCancel: () => void;
    fields: any[];
  }>;
}

const DataFormModal = <T,>({
  initialValues,
  onSubmit,
  fields,
  FormComponent,
}: GenericModalProps<T>) => {
  return (
    <FormComponent
      initialValues={initialValues}
      onSubmit={onSubmit}
      onCancel={ModalUtil.closeModal}
      fields={fields}
    />
  );
};

export default DataFormModal;
