import React from 'react';
import { Input } from 'antd';
import { Field } from 'formik';
import { evaluate } from 'mathjs';

interface EvaluatingFieldInputProps {
    name: string;
    placeholder?: string;
    onError?: (error: any) => void;
}

const EvaluatingFieldInput: React.FC<EvaluatingFieldInputProps> = ({ name, placeholder = 'Enter calculation', onError }) => (
    <Field name={name}>
        {({ field, form }: { field: any; form: any }) => {
            const value = form.getFieldProps(field.name).value;
            return (
                <Input
                    {...field}
                    value={value !== undefined && value !== null ? value : ''}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        form.setFieldValue(field.name, newValue);
                    }}
                    onBlur={() => {
                        try {
                            const currentValue = form.getFieldProps(field.name).value;
                            if (typeof currentValue === 'number') {
                                return;
                            }
                            const result = evaluate(currentValue);
                            if (!isNaN(result)) {
                                form.setFieldValue(field.name, result);
                            } else {
                                throw new Error('Result is NaN');
                            }
                        } catch (e) {
                            form.setFieldValue(field.name, 'Invalid expression');
                            if (onError) {
                                onError(e);
                            }
                        }
                    }}
                    placeholder={placeholder}
                    status={
                        form.touched[field.name] && form.errors[field.name] ? 'error' : ''
                    }
                />
            );
        }}
    </Field>
);

export default EvaluatingFieldInput;
