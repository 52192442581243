/* eslint-disable no-unused-vars */
import React from 'react';
import { FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import DataForm from '../../components/DataForm/DataForm';
import { useTranslation } from 'react-i18next';
import ModalUtil from '../../store/core/modalUtil';

interface NewOrderFormProps {
  initialValues: FormikValues;
  onSubmit: (values: FormikValues, actions?: FormikHelpers<FormikValues>) => void;
}

const NewOrderForm: React.FC<NewOrderFormProps> = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <DataForm
      initialValues={initialValues}
      validationSchema={Yup.object({
        orderNumber: Yup.string().required(t('stand.orderNumberRequired')),
      })}
      onSubmit={onSubmit}
      onCancel={ModalUtil.closeModal}
      fields={[
        {
          label: t('stand.orderNumber'),
          name: 'orderNumber',
          type: 'text',
          placeholder: t('stand.placeholderOrderNumber'),
        },
        { label: t('general.notes'), name: 'notes', type: 'text', placeholder: t('general.placeholderNotes') },
      ]}
    />
  );
};

export default NewOrderForm;
