import React, { useEffect } from 'react';
import { Button } from 'antd';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import useStandTypeStore from '../../store/standTypeStore';
import StandTypeFormModal from '../../components/StandTypes/StandTypeFormModal';
import { StandType } from '../../models/standTypes/StandType';
import Card from 'antd/es/card/Card';
import { useTranslation } from 'react-i18next';
import ModalUtil from '../../store/core/modalUtil';

const StandTypesPage: React.FC = () => {
  const { items, fetchItems, setSelectedItem, deleteItem } = useStandTypeStore();
  const { t } = useTranslation();

  const handleEdit = (id: string) => {
    const item = items.find((st) => st.id === id);

    if (item) {
      setSelectedItem(item);
      ModalUtil.openModal(<StandTypeFormModal onClose={ModalUtil.closeModal} initialValues={item!} />, t('stand.editStandType'), "500px");
    }
  };

  const handleAdd = () => {
    ModalUtil.openModal(<StandTypeFormModal onClose={ModalUtil.closeModal} />, t('stand.createStandType'), "500px");
  }

  const columns: DataTableColumn<StandType>[] = [
    { header: t('general.name'), accessor: 'name' },
    { header: t('general.notes'), accessor: 'notes' },
  ];

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return (
    <>
      <Card
        title={
          <>
            <span style={{ marginRight: '5px' }}>{t('stand.standTypes')}</span>
            <Button type="primary" onClick={handleAdd}>
              {t('stand.addStandType')}
            </Button>
          </>
        }
      >
        <DataTable
          columns={columns}
          data={items}
          onDelete={deleteItem}
          onEdit={handleEdit}
          enableFrontendFiltering
        />
      </Card>
    </>
  );
};

export default StandTypesPage;
