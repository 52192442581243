import { create } from 'zustand';
import { ReactNode } from 'react';

interface ModalContent {
    content: ReactNode;
    title?: string;
    footer?: ReactNode;
    width?: string;
    hideDefaultCloseButton?: boolean;
}

interface ModalStore {
    modals: ModalContent[];
    openModal: (modal: ModalContent) => void;
    closeModal: (e?: any, reason?: string) => void;
    closeAllModals: () => void;
}

export const useModalStore = create<ModalStore>((set) => ({
    modals: [],
    openModal: (modal) =>
        set((state) => ({
            modals: [...state.modals, modal],
        })),
    closeModal: (e: any, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        set((state) => ({ modals: state.modals.slice(0, -1) }));
    },
    closeAllModals: () => set({ modals: [] }),
}));