import { Row, Col, InputNumber, Radio, Checkbox } from 'antd';
import React, { ElementType } from 'react';
import { Formik, Form, Field, FormikHelpers, FormikValues, getIn } from 'formik';
import { Button, Input, Form as AntForm, DatePicker } from 'antd';
import * as Yup from 'yup';
import { Dayjs } from 'dayjs';
import DataFormCustomSelect from './DataFormCustomSelect';
import { useTranslation } from 'react-i18next';
import { evaluate } from 'mathjs'
const { Item: FormItem } = AntForm;

type FieldType = 'title' | 'text' | 'number' | 'select' | 'password' | 'email' | 'textarea' | 'date' | 'radio' | 'calculated' | 'checkbox';

export interface DataFieldPropsBase {
  label: string;
  name: string;
  type: FieldType;
  placeholder: string;
  component?: ElementType<any>;
  columnIndex?: number;
  rowIndex?: number;
  content?: string;
  precision?: number;
  step?: number;
  options?: any[];
  onChange?: (value: any, setFieldValue: (field: string, value: any) => void) => void;
}

export interface SelectFieldProps extends DataFieldPropsBase {
  type: 'select';
  options: {
    key?: string;
    value: any;
    label: string;
  }[];
}

export interface TitleFieldProps extends DataFieldPropsBase {
  type: 'title';
  content: string;
}

export type DataFieldProps = DataFieldPropsBase | SelectFieldProps | TitleFieldProps;

interface FormProps {
  initialValues: FormikValues;
  validationSchema?: Yup.ObjectSchema<any>;
  onSubmit: (
    values: FormikValues,
    formikHelpers?: FormikHelpers<FormikValues>,
  ) => void | Promise<void>;
  fields: DataFieldProps[];
  onCancel: () => void;
  numberOfColumns?: number;
}

const DataForm: React.FC<FormProps> = ({
  initialValues,
  validationSchema,
  onSubmit,
  fields,
  onCancel,
  numberOfColumns = 1
}) => {
  const { t } = useTranslation();

  const groupedFields = fields.reduce((acc, field, index) => {
    const row = field.rowIndex !== undefined ? field.rowIndex : Math.floor(index / numberOfColumns);

    if (!acc[row]) {
      acc[row] = [];
    }

    acc[row].push(field);
    return acc;
  }, {} as { [key: number]: DataFieldProps[] });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, formikHelpers) => {
        onSubmit(values, formikHelpers);
      }}
      enableReinitialize
    >
      {({ isSubmitting, errors, touched, setFieldValue, resetForm }) => (
        <Form>
          {Object.entries(groupedFields).map(([rowIndex, rowFields]) => (
            <Row key={rowIndex} gutter={16}>
              {rowFields.map((field, index) => {
                if (field.type === 'title') {
                  return (
                    <Col key={`title-${index}`} span={24}>
                      <div style={{ margin: '1em 0', fontWeight: 'bold', fontSize: '1.2em', textAlign: 'left' }}>
                        {field.content}
                      </div>
                    </Col>
                  );
                }

                const columnSpan = field.columnIndex !== undefined ? (24 / numberOfColumns) : numberOfColumns ? (24 / numberOfColumns) : 24;

                return (
                  <Col key={`field-${index}`} span={columnSpan}>
                    <FormItem
                      label={field.label ? <span>{field.label}</span> : null}
                      validateStatus={
                        getIn(touched, field.name) && getIn(errors, field.name)
                          ? 'error'
                          : ''
                      } help={
                        getIn(touched, field.name) && getIn(errors, field.name)
                          ? String(getIn(errors, field.name))
                          : ''
                      }                    >
                      {renderField(field, setFieldValue, touched, errors)}
                    </FormItem>
                  </Col>
                );
              })}
            </Row>
          ))}

          <div style={{ textAlign: 'right' }}>
            <Button
              type="default"
              onClick={() => {
                onCancel();
                resetForm();
              }}
              style={{ marginRight: '0.5em' }}
            >
              {t('general.cancel')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {t('general.submit')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const renderField = (field: DataFieldProps, setFieldValue: any, touched: any, errors: any) => {
  switch (field.type) {
    case 'radio':
      return (
        <Field name={field.name}>
          {({ field: formikField }: { field: any }) => (
            <Radio.Group
              value={formikField.value}
              onChange={(e) => setFieldValue(field.name, e.target.value)}
            >
              {(field.options || []).map((option: any, idx: number) => (
                <Radio key={idx} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          )}
        </Field>
      );
    case 'select':
      return (
        <Field
          name={field.name}
          placeholder={field.placeholder}
          options={(field as SelectFieldProps).options || []}
          component={DataFormCustomSelect}
          style={{ display: 'block' }}
          status={touched[field.name || ''] && errors[field.name || ''] ? 'error' : ''}
        />
      );
    case 'date':
      return (
        <Field name={field.name}>
          {({ field }: { field: any }) => (
            <DatePicker
              value={field.value ? field.value : null}
              onChange={(date: Dayjs | null) => setFieldValue(field.name || '', date)}
              placeholder={field.placeholder}
              style={{ width: '100%' }}
              format={'DD.MM.YYYY'}
              status={touched[field.name || ''] && errors[field.name || ''] ? 'error' : ''}
            />
          )}
        </Field>
      );
    case 'textarea':
      return (
        <Field name={field.name}>
          {({ field }: { field: any }) => (
            <Input.TextArea
              {...field}
              placeholder={field.placeholder}
              status={touched[field.name || ''] && errors[field.name || ''] ? 'error' : ''}
            />
          )}
        </Field>
      );
    case 'number':
      return (
        <Field name={field.name}>
          {({ field, form }: { field: any; form: any }) => (
            <InputNumber
              {...field}
              value={form.values[field.name]}
              onChange={(value: number | null) => {
                form.setFieldValue(field.name, value !== null ? value : 0);
              }}
              decimalSeparator=','
              placeholder={field.placeholder}
              style={{ width: '100%' }}
              status={touched[field.name || ''] && errors[field.name || ''] ? 'error' : ''}
            />
          )}
        </Field>
      );
    case 'calculated':
      return (
        <Field name={field.name}>
          {({ field, form }: { field: any; form: any }) => (
            <Input
              {...field}
              value={form.values[field.name]}
              onBlur={() => {
                try {
                  const result = evaluate(form.values[field.name]);
                  form.setFieldValue(field.name, result);
                } catch (e) {
                  form.setFieldValue(field.name, 'Invalid expression');
                }
              }}
              placeholder={field.placeholder || 'Enter calculation'}
              status={touched[field.name || ''] && errors[field.name || ''] ? 'error' : ''}
            />
          )}
        </Field>
      );
    case 'checkbox':
      return (
        <Field name={field.name}>
          {({ field: formikField }: { field: any }) => (
            <Checkbox
              checked={formikField.value}
              onChange={(e) => setFieldValue(field.name, e.target.checked)}
            >
            </Checkbox>
          )}
        </Field>
      );
    default:
      return (
        <Field name={field.name}>
          {({ field }: { field: any }) => (
            <Input
              {...field}
              type={field.type}
              placeholder={field.placeholder}
              status={touched[field.name || ''] && errors[field.name || ''] ? 'error' : ''}
            />
          )}
        </Field>
      );
  }
};

export default DataForm;