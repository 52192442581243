import React, { useState, useEffect } from 'react';
import { Product } from '../../models/products/products';
import agent from '../../api/agent';
import DataForm, { DataFieldProps } from '../DataForm/DataForm';
import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { ProductFormModalProps } from '../../models/products/productFormModalProps';
import { useProductStore } from '../../store/productStore';
import { useTranslation } from 'react-i18next';
import ModalUtil from '../../store/core/modalUtil';
const { v4: uuidv4 } = require('uuid');

const useProductTypes = () => {
  const [productTypes, setProductTypes] = useState<{ key: string; value: string; label: string }[]>(
    [],
  );

  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const types = await agent.ProductTypes.getAll();
        setProductTypes(
          types.map((type: any) => ({
            key: type.id!,
            value: type.id!,
            label: type.name!,
          })),
        );
      } catch (error) {
        console.error('Failed to fetch product types:', error);
      }
    };

    fetchProductTypes();
  }, []);

  return productTypes;
};

const ProductFormModal: React.FC<ProductFormModalProps> = ({ initialValues }) => {
  const { createItem, updateItem, fetchItems, setSelectedItem } = useProductStore();
  const productTypes = useProductTypes();
  const isEditMode = !!initialValues;
  const { t } = useTranslation();

  const handleSubmit = async (values: FormikValues, { setSubmitting, resetForm }: any) => {
    try {
      const data = {
        ...values,
        serviceCostPrice: Number(values.serviceCostPrice),
        creditNotePrice: Number(values.creditNotePrice),
        quantityPerBox: Number(values.quantityPerBox),
        depositPrice: Number(values.depositPrice),
      };

      if (isEditMode) {
        await updateItem(initialValues!.id!, data);
      } else {
        await createItem({
          id: uuidv4(),
          ...data,
        } as Product);
      }
      resetForm();
      setSelectedItem(null);
      ModalUtil.closeModal();

      await fetchItems();
    } catch (error) {
      console.error('Failed to save product:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const formFields: DataFieldProps[] = [
    { label: t('products.name'), name: 'name', type: 'text', placeholder: t('products.placeholderName') },
    { label: t('products.shortName'), name: 'shortName', type: 'text', placeholder: t('products.placeholderShortName') },
    {
      label: t('products.type'),
      name: 'typeId',
      type: 'select',
      placeholder: t('products.placeholderType'),
      options: productTypes,
    },
    { label: t('products.serviceCostPrice'), name: 'serviceCostPrice', type: 'number', placeholder: t('products.serviceCostPrice') },
    { label: t('products.creditNotePrice'), name: 'creditNotePrice', type: 'number', placeholder: t('products.creditNotePrice') },
    { label: t('products.depositPrice'), name: 'depositPrice', type: 'number', placeholder: t('products.depositPrice') },
    {
      label: t('products.quantityPerBox'),
      name: 'quantityPerBox',
      type: 'number',
      placeholder: t('products.quantityPerBox'),
    },
    { label: t('products.notes'), name: 'notes', type: 'textarea', placeholder: t('products.placeholderNotes') },
  ];

  const initialFormValues = {
    name: initialValues?.name || '',
    shortName: initialValues?.shortName || '',
    typeId: initialValues?.type?.id || '',
    serviceCostPrice: initialValues?.serviceCostPrice || 0,
    depositPrice: initialValues?.depositPrice || 0,
    creditNotePrice: initialValues?.creditNotePrice || 0,
    quantityPerBox: initialValues?.quantityPerBox || 0,
    notes: initialValues?.notes || '',
  };

  return (
    <DataForm
      initialValues={initialFormValues}
      validationSchema={Yup.object({
        name: Yup.string().required(t('products.nameIsRequired')),
        price: Yup.number(),
        quantityPerBox: Yup.number().required(t('products.quantityPerBox')),
        typeId: Yup.string().required(t('products.typeIsRequired')),
      })}
      onSubmit={handleSubmit}
      onCancel={() => { setSelectedItem(null); ModalUtil.closeModal() }}
      fields={formFields}
    />
  );
};

export default ProductFormModal;
