import React, { useEffect } from 'react';
import { Button, Card } from 'antd';
import useProductTypeStore from '../../store/productTypeStore';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import ProductTypeFormModal from '../../components/ProductTypes/ProductTypeFormModal';
import { ProductType } from '../../models/productTypes/productType';
import { useTranslation } from 'react-i18next';
import ModalUtil from '../../store/core/modalUtil';

const ProductTypesPage: React.FC = () => {
  const { items, setSelectedItem, fetchItems, deleteItem } = useProductTypeStore();
  const { t } = useTranslation();

  const handleEdit = (productTypeId: string) => {
    const item = items.find((x) => x.id === productTypeId);

    if (item) {
      setSelectedItem(item);
      ModalUtil.openModal(<ProductTypeFormModal onClose={ModalUtil.closeModal} initialValues={item} />, t('productTypes.editProductType'), "500px");
    }

  };

  const handleAdd = () => {
    ModalUtil.openModal(<ProductTypeFormModal onClose={ModalUtil.closeModal} />, t('productTypes.createProductType'), "500px");
  };

  const columns: DataTableColumn<ProductType>[] = [
    { header: t('productTypes.name'), accessor: 'name' },
    { header: t('productTypes.notes'), accessor: 'notes' },
  ];

  useEffect(() => {
    fetchItems();
  }, [fetchItems])

  return (
    <>
      <Card
        title={
          <>
            <span style={{ marginRight: '5px' }}>{t('productTypes.title')}</span>
            <Button type="primary" onClick={handleAdd}>
              {t('productTypes.addType')}
            </Button>
          </>
        }
      >
        <DataTable
          columns={columns}
          data={items}
          onDelete={deleteItem}
          onEdit={handleEdit}
          enableFrontendFiltering
        />
      </Card>
    </>
  );
};

export default ProductTypesPage;
