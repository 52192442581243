import { ReactNode } from 'react';
import { useModalStore } from './modalStore';

interface ModalConfig {
    content: ReactNode;
    title?: string;
    footer?: ReactNode;
    width?: string;
    hideDefaultCloseButton?: boolean;
}

class ModalUtil {
    static lastOpenedModal: string | null = null;
    static debounceTimeout: ReturnType<typeof setTimeout> | null = null;

    static debounce = (fn: (...args: any[]) => void, delay: number) => {
        return (...args: any[]) => {
            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout);
            }
            this.debounceTimeout = setTimeout(() => {
                fn(...args);
            }, delay);
        };
    };

    static openModal(config: ModalConfig | ReactNode, title?: string, width?: string) {
        const openModal = useModalStore.getState().openModal;
        // avoid to open the same modal more than once
        const contentType: any = (config as any)?.type || (config as any)?.content?.type;
        const currentModalName = typeof this.lastOpenedModal === 'string' ? this.lastOpenedModal : null;
        const newModalName =
            typeof contentType === 'string' ? contentType : contentType?.displayName || contentType?.name || 'Unknown';

        if (currentModalName !== newModalName) {
            this.lastOpenedModal = newModalName;
            this.debounce(() => {
                if (this.isModalConfig(config)) {
                    useModalStore.getState().openModal(config);
                } else {
                    openModal({ content: config as any, title, width });
                }
            }, 100)();
        }
    }

    static closeAllModals = () => {
        const closeAllModals = useModalStore.getState().closeAllModals;
        closeAllModals();
        this.lastOpenedModal = null;
    };

    static closeModal = (e?: any, reason: string = '') => {
        const closeModal = useModalStore.getState().closeModal;
        closeModal(e, reason);
        this.lastOpenedModal = null;
    };

    private static isModalConfig(config: any): config is ModalConfig {
        return typeof config === 'object' && 'content' in config;
    }
}

export default ModalUtil;