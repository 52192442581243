import React, { useEffect } from 'react';
import { Stand } from '../../models/stands/stand';
import DataForm, { DataFieldProps } from '../DataForm/DataForm';
import * as Yup from 'yup';
import useStandSectorStore from '../../store/standSectoreStore';
import useStandStore from '../../store/standStore';
const { v4: uuidv4 } = require('uuid');
import { useTranslation } from 'react-i18next';
import useStandTypeStore from '../../store/standTypeStore';

interface AddStandModalProps {
  onClose: () => void;
  eventId: string;
  initialValues?: Stand | null;
  customerId: string;
}

const AddStandModal: React.FC<AddStandModalProps> = ({ onClose, eventId, initialValues, customerId }) => {
  const { sectorsByEventId: standSectors, fetchStandSectorsByEventId: fetchStandSectorsByEventId } = useStandSectorStore();
  const { setSelectedItem: setSelectedStand, fetchStandsByEventId } = useStandStore();
  const { createItem: addStand, updateItem: updateStand } = useStandStore();
  const { items: standTypes, fetchItems: fetchStandType } = useStandTypeStore();
  const { t } = useTranslation();

  useEffect(() => {
    fetchStandType();
    fetchStandSectorsByEventId(eventId, true);
  }, [eventId, fetchStandType, fetchStandSectorsByEventId]);

  const handleSubmit = async (values: any) => {
    try {
      if (initialValues) {
        const updatedStand = {
          ...values,
          eventId,
          customerId: initialValues.customer?.id,
        };

        await updateStand(initialValues.id!, updatedStand);
      } else {
        const newStand = { ...values, eventId, id: uuidv4(), customerId };
        await addStand(newStand);

        setSelectedStand(null)
      }

      fetchStandsByEventId(eventId, true);
      onClose();
    } catch (error) {
      console.error('Failed to save stand:', error);
    }
  };

  const formFields: DataFieldProps[] = [
    {
      label: t('general.name'),
      name: 'name',
      type: 'text',
      placeholder: 'Enter stand name',
    },
    {
      label: t('stand.type'),
      name: 'typeId',
      type: 'select',
      placeholder: 'Select type',
      options: standTypes?.map((s) => ({
        key: s.id!,
        value: s.id!,
        label: s.name,
      })),
    },
    {
      label: t('stand.sector'),
      name: 'sectorId',
      type: 'select',
      placeholder: 'Select sector',
      options: standSectors?.map((s) => ({
        key: s.id!,
        value: s.id!,
        label: s.name!,
      })),
    },
    {
      label: t('stand.isDeliveryDone'),
      name: 'isDeliveryDone',
      type: 'checkbox',
      placeholder: 'Lieferung erledigt?',
    },
  ];

  return (
    <DataForm
      initialValues={{
        name: initialValues?.name || '',
        typeId: initialValues?.type?.id,
        sectorId: initialValues?.sector?.id,
        isDeliveryDone: initialValues?.isDeliveryDone
      }}
      validationSchema={Yup.object({
        name: Yup.string().required(t('stand.messageNotifier.nameRequired')),
        typeId: Yup.string().required(t('stand.messageNotifier.typeRequired')),
      })}
      onSubmit={handleSubmit}
      onCancel={onClose}
      fields={formFields}
    />
  );
};

export default AddStandModal;
