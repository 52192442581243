import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button } from 'antd';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import CustomerFormModal from '../../components/Customers/CustomerFormModal';
import useCustomerStore from '../../store/customerStore';
import { Customer } from '../../models/customers';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../../store/authUserStore';
import ModalUtil from '../../store/core/modalUtil';

const Customers: React.FC = () => {
  const { items: items, fetchItems, setSelectedItem, deleteItem } = useCustomerStore();
  const { currentUser } = useAuthStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEdit = (customerId: string) => {
    const item = items.find((x) => x.id === customerId);
    setSelectedItem(item!);

    ModalUtil.openModal(<CustomerFormModal initialValues={item as any} />, item ? t('customer.edit') : t('customer.create'), "80%");
  };

  const handleRowClick = (customerId: string) => {
    navigate(`/customers/${customerId}`);
  };

  const handleCreateCustomer = () => {
    ModalUtil.openModal(<CustomerFormModal initialValues={null} />, t('customer.create'), "80%");
  };

  const columns: DataTableColumn<Customer>[] = [
    { header: t('customer.firstName'), accessor: 'firstName', type: 'text' },
    { header: t('customer.lastName'), accessor: 'lastName' },
    { header: t('customer.companyName'), accessor: 'companyName' },
    { header: t('customer.username'), accessor: 'username' },
    { header: t('customer.phone'), accessor: 'phone' },
    { header: t('customer.email'), accessor: 'email' },
  ];

  useEffect(() => {
    fetchItems();
  }, [fetchItems])

  return (
    <>
      <Card
        title={
          <>
            <span style={{ marginRight: '5px' }}>Customers</span>
            {currentUser?.role === 'admin' &&
              <Button type="primary" onClick={handleCreateCustomer}>
                {t('customer.create')}
              </Button>
            }
          </>
        }
      >
        <DataTable
          columns={columns}
          data={items}
          onEdit={handleEdit}
          onRowClick={(customer) => handleRowClick(customer.id!)}
          onDelete={deleteItem}
          enableFrontendFiltering
        />
      </Card>
    </>
  );
};

export default Customers;
