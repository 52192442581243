import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Typography, Button, Card, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useOrderStore from '../../store/orderStore';
import { NewOrder } from '../../models/orders/newOrder';
import { OrderItem } from '../../models/orderItems/orderItem';
import { Order } from '../../models/orders/order';
import { notifyError } from '../../components/NotificationProvider';
import NewOrderForm from './NewOrderForm';
import OrderItemForm from './OrderItemForm';
import { FormikValues } from 'formik';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import { OrderStatus } from '../../models/orders/orderStatus';
import useStandStore from '../../store/standStore';
import { useProductStore } from '../../store/productStore';
import useOrderItemStore from '../../store/orderItemStore';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../../store/authUserStore';
import _ from 'lodash';
import ModalUtil from '../../store/core/modalUtil';

const { v4: uuidv4 } = require('uuid');

const StandDetails: React.FC = () => {
  const { Title, Paragraph } = Typography;
  const { id } = useParams<{ id: string }>();
  const { selectedItem: selectedStand, fetchItemById: fetchStandById } = useStandStore();
  const { productsOfSelectedEvent, fetchProductsByEvent } = useProductStore();
  const { selectedStandOrders, createItem: createOrder, deleteItem: deleteOrder, downloadPDFByBillType, fetchOrdersByStandId, updateItem: updateOrder } = useOrderStore();
  const { currentUser } = useAuthStore();
  const { items: orderItemsByEvent, createItem: createOrderItem, deleteItem: deleteOrderItem, updateItem: updateOrderItem, fetchOrderItemsByEventId, setSelectedItem: setSelectedOrderItem } = useOrderItemStore();
  const [newOrder, setNewOrder] = useState<NewOrder>(() => ({
    id: uuidv4(),
    standId: id || '',
    orderNumber: uuidv4().substring(0, 8).toUpperCase(),
    totalPrice: 0,
    customerId: selectedStand?.customerId || '',
    status: OrderStatus.Pending,
    eventId: selectedStand?.eventId || ''
  }));
  const { t } = useTranslation();

  useEffect(() => {
    fetchStandById(id!);
    fetchOrdersByStandId(id!);
  }, [id, fetchOrdersByStandId, fetchStandById]);

  useEffect(() => {
    if (selectedStand) {
      setNewOrder((prevState) => ({
        ...prevState,
        customerId: selectedStand?.customerId || '',
      }));
      fetchProductsByEvent(selectedStand?.eventId!, true);
      fetchOrderItemsByEventId(selectedStand?.eventId!, true);
    }
  }, [selectedStand, fetchProductsByEvent, fetchOrderItemsByEventId]);

  const handleCreateOrder = async (values: FormikValues, { setSubmitting, resetForm }: any) => {
    if (!values.orderNumber) {
      notifyError(t('stand.messageNotifier.numberRequired'));
      return;
    }

    try {
      const orderToCreate: NewOrder = {
        ...newOrder,
        orderNumber: values.orderNumber,
        totalPrice: +values.totalPrice,
        customerId: selectedStand?.customerId || '',
        status: OrderStatus.Pending,
        eventId: selectedStand?.eventId || ''
      };

      await createOrder(orderToCreate as any);

      setNewOrder({
        id: uuidv4(),
        standId: id || '',
        orderNumber: uuidv4().substring(0, 8).toUpperCase(),
        totalPrice: 0,
        customerId: selectedStand?.customerId || '',
        status: OrderStatus.Pending,
        eventId: selectedStand?.eventId || '',
      });
      resetForm();
      fetchOrdersByStandId(id!);
    } catch (error) {
      notifyError(t('stand.messageNotifier.failedToCreateOrder'));
    } finally {
      setSubmitting(false);
      ModalUtil.closeModal();
    }
  };

  const handleCreateOrderItem = async (values: FormikValues, propOrderItem: OrderItem, currentOrder: Order) => {
    if (!currentOrder) return;
    if (!values.productId) return;
    const filteredOrderItemsData = [];
    try {
      let orderItem = propOrderItem;

      if (orderItem?.id) {
        orderItem = { ...values, orderId: currentOrder.id, eventId: selectedStand?.event?.id } as OrderItem;
        await updateOrderItem(orderItem.id, orderItem)
      } else {
        orderItem = { ...values, orderId: currentOrder.id!, id: uuidv4(), eventId: selectedStand?.event?.id } as OrderItem;
        await createOrderItem(orderItem);
      }

      filteredOrderItemsData.push(orderItem);
      filteredOrderItemsData.push(...orderItemsByEvent.filter(x => x.orderId === currentOrder.id && x.id !== orderItem?.id));
      const orderData = {
        ...currentOrder,
        totalIssued: +filteredOrderItemsData.map(orderItem => _.parseInt(orderItem.issuedItems?.totalQuantity as any) || 0).reduce((accumulator, currentValue) => accumulator + currentValue),
        totalClean: +filteredOrderItemsData.map(orderItem => _.parseInt(orderItem.cleanItems?.totalQuantity as any) || 0).reduce((accumulator, currentValue) => accumulator + currentValue),
        totalUnused: +filteredOrderItemsData.map(orderItem => _.parseInt(orderItem.unusedItems?.totalQuantity as any) || 0).reduce((accumulator, currentValue) => accumulator + currentValue),
        totalUsed: +filteredOrderItemsData.map(orderItem => _.parseInt(orderItem.usedItems?.totalQuantity as any) || 0).reduce((accumulator, currentValue) => accumulator + currentValue),
      };

      await updateOrder(currentOrder.id, orderData)

      setSelectedOrderItem(orderItem);
      await fetchOrdersByStandId(id!);
      await fetchOrderItemsByEventId(selectedStand?.eventId!, true);
    } catch (error) {
      notifyError(t('stand.messageNotifier.failedToSubmitOrder'));
    } finally {
      ModalUtil.closeModal();
    }
  };

  const handleDeleteOrder = async (orderId: string) => {
    try {
      await deleteOrder(orderId);
      await fetchOrdersByStandId(id!);
    } catch (error) {
      notifyError(t('stand.messageNotifier.failedToDelteOrder'));
    }
  };

  const handleDeleteOrderItem = async (orderItemId: string) => {
    try {
      await deleteOrderItem(orderItemId);
      await fetchOrdersByStandId(id!);
      await fetchOrderItemsByEventId(selectedStand?.event?.id!, true);
    } catch (error) {
      notifyError(t('stand.messageNotifier.failedTodeleteOrderItem'));
    }
  };

  const handleEditOrderItem = async (orderItemId: string, orderItems: OrderItem[], order: Order) => {
    const currentOrderItem = orderItems.find(oi => oi.id == orderItemId);

    if (currentOrderItem) {
      setSelectedOrderItem({ ...currentOrderItem } as OrderItem);

      ModalUtil.openModal(<OrderItemForm
        initialValues={currentOrderItem!}
        onSubmit={(values: any) => handleCreateOrderItem(values, currentOrderItem, order)}
        onCancel={handlCloseModalOrderItem}
        products={productsOfSelectedEvent}
      />, currentOrderItem?.id ? t('stand.editOrderItem') : t('stand.addOrderItem'));

      await fetchOrderItemsByEventId(selectedStand?.event?.id!, true);
    }
  };

  const handlCloseModalOrderItem = async () => {
    setSelectedOrderItem(null);
    ModalUtil.closeModal();
  };

  const handleDownloadPDFByBillType = async (orderId: string, key: string) => {
    try {
      await downloadPDFByBillType(orderId, key);
    } catch (error) {
      notifyError(`${t('general.errorDownloadingPDF')}: ${error}`);
    }
  };

  if (!selectedStand) {
    return <div>{t('stand.standNotFound')}</div>;
  }

  const orderColumns: DataTableColumn<Order>[] = [
    { header: t('order.number'), accessor: 'orderNumber' },
    { header: t('order.totalIssued'), accessor: 'totalIssued', type: 'number' },
    { header: t('order.totalUsed'), accessor: 'totalUsed', type: 'number' },
    { header: t('order.totalUnused'), accessor: 'totalUnused', type: 'number' },
    { header: t('order.totalClean'), accessor: 'totalClean', type: 'number' },
  ];

  const orderItemColumns: DataTableColumn<OrderItem>[] = [
    { header: t('order.productName'), accessor: 'product.name' },
    { header: t('order.totalIssued'), accessor: 'issuedItems.totalQuantity', type: 'number' },
    { header: t('order.totalUsed'), accessor: 'usedItems.totalQuantity', type: 'number' },
    { header: t('order.totalUnused'), accessor: 'unusedItems.totalQuantity', type: 'number' },
    { header: t('order.totalClean'), accessor: 'cleanItems.totalQuantity', type: 'number' },
  ];

  const downloadColumns: { key: string, displayValue: string }[] = [
    { key: 'deliverynote', displayValue: t('stand.deliveryNote') },
    { key: 'redemptionslip', displayValue: t('stand.redemptionSlip') }
  ];

  const expandedRowRender = (order: Order) => {
    const filteredOrderItemsData = orderItemsByEvent.filter(x => x.orderId === order?.id);

    return <>
      {currentUser?.role == 'admin' &&
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          onClick={() => {
            ModalUtil.openModal(<OrderItemForm
              initialValues={{} as any}
              onSubmit={(values) => handleCreateOrderItem(values, null as any, order)}
              onCancel={() => handlCloseModalOrderItem()}
              products={productsOfSelectedEvent}
            />, t('stand.addOrderItem'));

            setSelectedOrderItem({} as any)
          }}
        >
          {t('stand.addItem')}
        </Button>
      }
      <DataTable
        columns={orderItemColumns}
        data={filteredOrderItemsData}
        onDelete={handleDeleteOrderItem}
        onEdit={currentUser?.role == 'admin' ? async (orderItemId) => await handleEditOrderItem(orderItemId, filteredOrderItemsData, order) : undefined}
        hidePagination
      />
    </>
  }

  const onOpenOrderModal = () => {
    ModalUtil.openModal(<NewOrderForm initialValues={newOrder} onSubmit={handleCreateOrder} />,
      t('stand.createDeliveryBill'), "500px");
  }

  return (
    <>
      <div style={{ border: '1px solid #f0f2f5', padding: '20px', borderRadius: '5px', marginBottom: '20px' }}>
        <Row justify="space-between">
          <Col>
            <Title level={2} style={{ margin: 0 }}>{selectedStand.name}</Title>
            <Paragraph style={{ margin: '5px 0' }}>
              <strong>{t('stand.customer')}</strong> <Link to={`/customers/${selectedStand.customer?.id}`}>{selectedStand.customer?.companyName}</Link>
            </Paragraph>
            <Paragraph style={{ margin: '5px 0' }}>
              <strong>{t('stand.type')}</strong> {selectedStand.type?.name}
            </Paragraph>
            <Paragraph style={{ margin: '5px 0' }}>
              <strong>{t('stand.sector')}</strong> {selectedStand.sector?.name}
            </Paragraph>
          </Col>
        </Row>
      </div>

      <Card style={{ marginTop: '1em' }}>
        <Title level={3}>{t('stand.deliveryBills')}</Title>
        {currentUser?.role == 'admin' &&
          <Button style={{ marginBottom: '1em' }} type="primary" icon={<PlusOutlined />} onClick={onOpenOrderModal}>
            {t('stand.createDeliveryBill')}
          </Button>
        }
        <DataTable
          columns={orderColumns}
          data={selectedStandOrders}
          expandedRowRender={expandedRowRender}
          onDelete={handleDeleteOrder}
          onDownload={handleDownloadPDFByBillType}
          downloadColumns={downloadColumns}
          defaultExpandAllRows
        />
      </Card>
    </>
  );
};

export default StandDetails;
