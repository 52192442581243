import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useEventStore from '../../store/eventStore';
import useStandTypeStore from '../../store/standTypeStore';
import { Customer } from '../../models/customers';
import DataForm, { DataFieldProps } from '../../components/DataForm/DataForm';
import ModalUtil from '../../store/core/modalUtil';

export interface Props {
    customer?: Customer;
    downloadType: number;
}

export default function DownloadReportModal({ customer, downloadType }: Props) {
    const { selectedItem: selectedEvent, downloadInvoiceByCustomerId, downloadIssuedArticlesReportByCustomerId, downloadIssuedEventArticlesByCustomerReport, downloadIssuedEventArticlesByProductReport } = useEventStore();
    const { items: standTypes, fetchItems: fetchStandTypes } = useStandTypeStore();
    const { t } = useTranslation();

    const confirmDownload = (values: any) => {
        if (downloadType === 1) {
            downloadInvoiceByCustomerId(customer!, selectedEvent?.id!, values.standType!);
        } else if (downloadType === 2) {
            downloadIssuedArticlesReportByCustomerId(customer!, selectedEvent?.id!, values.standType!);
        } else if (downloadType === 3) {
            downloadIssuedEventArticlesByCustomerReport(selectedEvent?.id!, values.standType!);
        } else if (downloadType === 4) {
            downloadIssuedEventArticlesByProductReport(selectedEvent?.id!, values.standType!);
        }
        ModalUtil.closeModal();
    };

    useEffect(() => {
        fetchStandTypes();
    }, [])

    const radioOptions = [
        { value: 'all', label: t('stand.all') },
        ...standTypes.map((standType) => ({
            value: standType.id,
            label: standType.name,
        })),
    ];

    if (!standTypes.length) {
        return null;
    }

    return (
        <DataForm
            initialValues={{ standType: 'all' }}
            fields={[
                {
                    name: 'standType',
                    type: 'radio',
                    label: t('stand.standTypes'),
                    placeholder: t('stand.standTypes'),
                    options: radioOptions,
                } as DataFieldProps,
            ]}
            onSubmit={confirmDownload}
            onCancel={ModalUtil.closeModal}
        />
    );
}

