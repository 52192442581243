import React, { useEffect } from 'react';
import { Formik, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Form, Input, Select, Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Product } from '../../models/products/products';
import { OrderItem } from '../../models/orderItems/orderItem';
import _ from 'lodash';
import EvaluatingFieldInput from '../../components/EvaluatingFieldInput';
import ModalUtil from '../../store/core/modalUtil';

interface OrderItemFormProps {
  initialValues: OrderItem;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  products: Product[];
}

const { Item: FormItem } = Form;
const { Option } = Select;

const OrderItemForm: React.FC<OrderItemFormProps> = ({ initialValues, onSubmit, onCancel, products }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    productId: Yup.string().required(t('order.messageNotifier.productRequired')),
  });

  const handleProductChange = (value: any, setFieldValue: (field: string, value: any) => void) => {
    const selectedProduct = products.find(product => product.id === value);
    if (selectedProduct) {
      setFieldValue('productId', selectedProduct.id);
      setFieldValue('issuedItems.unitsPerBox', selectedProduct.quantityPerBox);

      if (initialValues?.id) {
        setFieldValue('usedItems.unitsPerBox', selectedProduct.quantityPerBox);
        setFieldValue('unusedItems.unitsPerBox', selectedProduct.quantityPerBox);
        setFieldValue('cleanItems.unitsPerBox', selectedProduct.quantityPerBox);
      }
    }
  };

  const calculateTotal = (values: any, section: string) => {
    const sectionData = values[section];

    if (!sectionData) {
      return 0;
    }

    const { multiplier = 0, unitsPerBox = 0, singleUnits = 0 } = sectionData;
    return _.parseInt(multiplier) * _.parseInt(unitsPerBox) + _.parseInt(singleUnits);
  };

  const UpdateUnitsPerBox = () => {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
      if (initialValues?.productId) {
        const selectedProduct = products.find(product => product.id === initialValues.productId);

        if (selectedProduct) {
          setFieldValue('issuedItems.unitsPerBox', initialValues.issuedItems?.unitsPerBox || selectedProduct.quantityPerBox);
          setFieldValue('usedItems.unitsPerBox', initialValues.usedItems?.unitsPerBox || selectedProduct.quantityPerBox);
          setFieldValue('unusedItems.unitsPerBox', initialValues.unusedItems?.unitsPerBox || selectedProduct.quantityPerBox);
          setFieldValue('cleanItems.unitsPerBox', initialValues.cleanItems?.unitsPerBox || selectedProduct.quantityPerBox);
        }
      }
    }, [setFieldValue]);

    return null; // No UI, just need the effect
  };


  return (
    <Formik
      initialValues={{
        ...initialValues,
        productId: initialValues?.productId || "",
        issuedItems: {
          multiplier: _.parseInt(initialValues?.issuedItems?.multiplier as any) || 0,
          unitsPerBox: _.parseInt(initialValues?.issuedItems?.unitsPerBox as any) || 0,
          singleUnits: _.parseInt(initialValues?.issuedItems?.singleUnits as any) || 0,
          totalQuantity: _.parseInt(initialValues?.issuedItems?.totalQuantity as any)
        },
        usedItems: {
          multiplier: _.parseInt(initialValues?.usedItems?.multiplier as any) || 0,
          unitsPerBox: _.parseInt(initialValues?.usedItems?.unitsPerBox as any) || 0,
          singleUnits: _.parseInt(initialValues?.usedItems?.singleUnits as any) || 0,
          totalQuantity: _.parseInt(initialValues?.issuedItems?.totalQuantity as any)
        },
        unusedItems: {
          multiplier: _.parseInt(initialValues?.unusedItems?.multiplier as any) || 0,
          unitsPerBox: _.parseInt(initialValues?.unusedItems?.unitsPerBox as any) || 0,
          singleUnits: _.parseInt(initialValues?.unusedItems?.singleUnits as any) || 0,
          totalQuantity: _.parseInt(initialValues?.unusedItems?.totalQuantity as any)
        },
        cleanItems: {
          multiplier: _.parseInt(initialValues?.cleanItems?.multiplier as any) || 0,
          unitsPerBox: _.parseInt(initialValues?.cleanItems?.unitsPerBox as any) || 0,
          singleUnits: _.parseInt(initialValues?.cleanItems?.singleUnits as any) || 0,
          totalQuantity: _.parseInt(initialValues?.cleanItems?.totalQuantity as any)
        },
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        values.issuedItems.totalQuantity = calculateTotal(values, 'issuedItems');
        values.usedItems.totalQuantity = calculateTotal(values, 'usedItems');
        values.unusedItems.totalQuantity = calculateTotal(values, 'unusedItems');
        values.cleanItems.totalQuantity = calculateTotal(values, 'cleanItems');
        await onSubmit(values);
        resetForm();
      }}
      enableReinitialize
    >
      {({ values, setFieldValue, handleSubmit, isSubmitting, touched, errors, resetForm }) => (
        <Form layout="vertical" onFinish={handleSubmit as any}>
          <UpdateUnitsPerBox />
          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                label={t('order.product')}
                validateStatus={touched.productId && errors.productId ? 'error' : ''}
                help={touched.productId && typeof errors.productId === 'string' ? errors.productId : undefined}
              >
                <Select
                  id={"productId"}
                  value={values.productId}
                  placeholder={t('order.placeholderSelectProduct')}
                  onChange={(value) => handleProductChange(value, setFieldValue)}
                >
                  {products.map((product) => (
                    <Option key={product.id} value={product.id}>
                      {product.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>

          <>
            <h3>{t('order.issuedOrderItems')}</h3>
            <Row gutter={16}>
              <Col span={6}>
                <FormItem label={t('order.multiplier')}>
                  <Field as={Input} name="issuedItems.multiplier" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.unitsPerBox')}>
                  <Field as={Input} name="issuedItems.unitsPerBox" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.singleUnits')}>
                  <EvaluatingFieldInput name="issuedItems.singleUnits" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.totalQuantity')}>
                  <Input value={calculateTotal(values, 'issuedItems')} name="issuedItems.totalQuantity" type="number" readOnly />
                </FormItem>
              </Col>
            </Row>
          </>

          <>
            <h3>{t('order.usedOrderItems')}</h3>
            <Row gutter={16}>
              <Col span={6}>
                <FormItem label={t('order.multiplier')}>
                  <Field as={Input} name="usedItems.multiplier" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.unitsPerBox')}>
                  <Field as={Input} name="usedItems.unitsPerBox" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.singleUnits')}>
                  <EvaluatingFieldInput name="usedItems.singleUnits" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.totalQuantity')}>
                  <Input value={calculateTotal(values, 'usedItems')} name="usedItems.totalQuantity" type="number" readOnly />
                </FormItem>
              </Col>
            </Row>

            <h3>{t('order.unusedOrderItems')}</h3>
            <Row gutter={16}>
              <Col span={6}>
                <FormItem label={t('order.multiplier')}>
                  <Field as={Input} name="unusedItems.multiplier" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.unitsPerBox')}>
                  <Field as={Input} name="unusedItems.unitsPerBox" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.singleUnits')}>
                  <EvaluatingFieldInput name="unusedItems.singleUnits" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.totalQuantity')}>
                  <Input value={calculateTotal(values, 'unusedItems')} name="unusedItems.totalQuantity" type="number" readOnly />
                </FormItem>
              </Col>
            </Row>

            <h3>{t('order.cleanOrderItems')}</h3>
            <Row gutter={16}>
              <Col span={6}>
                <FormItem label={t('order.multiplier')}>
                  <Field as={Input} name="cleanItems.multiplier" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.unitsPerBox')}>
                  <Field as={Input} name="cleanItems.unitsPerBox" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.singleUnits')}>
                  <EvaluatingFieldInput name="cleanItems.singleUnits" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.totalQuantity')}>
                  <Input value={calculateTotal(values, 'cleanItems')} name="cleanItems.totalQuantity" type="number" readOnly />
                </FormItem>
              </Col>
            </Row>
          </>

          <FormItem style={{ textAlign: 'right' }}>
            <Button
              type="default"
              onClick={() => {
                resetForm();
                onCancel();
              }}
              style={{ marginRight: '10px' }}
            >
              {t('general.cancel')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {t('general.submit')}
            </Button>
          </FormItem>
        </Form>
      )}
    </Formik>
  );
};

export default OrderItemForm;
