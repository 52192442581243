import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { ConfigProvider, Layout, theme } from 'antd';
import SidebarComponent from './components/Sidebar/SidebarComponent';
import Login from './components/Login';
import User from './pages/Users';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './components/Dashboard';
import Products from './pages/Products/Products';
import Customers from './pages/Customers/Customers';
import HeaderComponent from './components/HeaderComponent/HeaderComponent';
import { NotificationProvider } from './components/NotificationProvider';
import Home from './components/Home';
import Events from './pages/Events/Events';
import EventDetails from './pages/Events/EventDetails';
import StandDetails from './pages/Stands/StandDetails';
import ProductTypesPage from './pages/ProductTypes/ProductTypePage';
import StandTypesPage from './pages/StandTypes/StandTypesPage';
import CustomerDetailsScreen from './pages/Customers/CustomerDetails';
import ProductDetailsScreen from './pages/Products/ProductDetails';
import OrderDetailsScreen from './pages/Orders/OrderDetails';
import ForgotPassword from './components/FortgotPassword';
import ResetPassword from './components/ResetPassword';
import CountriesPage from './pages/Countries/CountryPage';
import { ModalComponent } from './components/Modal';

const { Content } = Layout;

const App: React.FC = () => {
  return (
    <ConfigProvider
      theme={{ algorithm: theme.defaultAlgorithm, token: { colorPrimary: '#00b96b' } }}
    >
      <Router>
        <NotificationProvider>
          <Layout style={{ minHeight: '100vh' }}>
            <HeaderComponent />
            <Layout>
              <SidebarComponent />
              <Layout style={{ backgroundColor: '#fff', marginTop: '70px' }}>
                <Content style={{ margin: '20px 20px', minHeight: 'calc(100vh - 60px)' }}>
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:id" element={<ResetPassword />} />
                    <Route path="/" element={<ProtectedRoute component={Home} />} />
                    <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
                    <Route path="/users" element={<ProtectedRoute component={User} />} />
                    <Route path="/products" element={<ProtectedRoute component={Products} />} />
                    <Route path="/customers" element={<ProtectedRoute component={Customers} />} />
                    <Route path="/events" element={<ProtectedRoute component={Events} />} />
                    <Route path="/events/details/:id" element={<ProtectedRoute component={EventDetails} />} />
                    <Route path="/stands/details/:id" element={<ProtectedRoute component={StandDetails} />} />
                    <Route path="/product-types" element={<ProductTypesPage />} />
                    <Route path="/stand-types" element={<StandTypesPage />} />
                    <Route path="/customers/:customerId" element={<CustomerDetailsScreen />} />
                    <Route path="/products/:productId" element={<ProductDetailsScreen />} />
                    <Route path="/orders/details/:id" element={<ProtectedRoute component={OrderDetailsScreen} />} />
                    <Route path="/my-profile" element={<CustomerDetailsScreen />} />
                    <Route path="/countries" element={<CountriesPage />} />
                  </Routes>
                </Content>
              </Layout>
              <ModalComponent />
            </Layout>
          </Layout>
        </NotificationProvider>
      </Router>
    </ConfigProvider>
  );
};

export default App;
