import React, { useState, useEffect } from 'react';
import { FormikValues } from 'formik';
import DataFormModal from '../DataForm/DataFormModal';
import DataForm from '../DataForm/DataForm';
import { CountryFormModalProps } from '../../models/countries/countryFormModal';
import { useTranslation } from 'react-i18next';
import { NewCountry } from '../../models/countries/newCountry';
import useCountryStore from '../../store/countryStore';
import { Country } from '../../models/countries/country';
const { v4: uuidv4 } = require('uuid');

const CountryFormModal: React.FC<CountryFormModalProps> = ({
  onClose,
  initialValues,
}) => {
  const { createItem, updateItem } = useCountryStore();
  const [isEditMode, setIsEditMode] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialValues) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [initialValues]);

  const handleSubmit = async (values: FormikValues, { setSubmitting, resetForm }: any) => {
    try {
      if (isEditMode && initialValues) {
        await updateItem(values.id!, {
          id: uuidv4(),
          ...values,
        });
      } else {
        await createItem(values as Country);
      }
      resetForm();
      onClose();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <DataFormModal<NewCountry>
      initialValues={
        initialValues || {
          id: uuidv4(),
          name: '',
          countryCode: '',
          taxPercentage: 0,
        }
      }
      onSubmit={handleSubmit}
      fields={[
        { label: t('countries.name'), name: 'name', type: 'text', placeholder: t('countries.name') },
        {
          label: t('countries.countryCode'),
          name: 'countryCode',
          type: 'text',
          placeholder: t('countries.countryCode'),
        },
        { label: t('countries.taxPercentage'), name: 'taxPercentage', type: 'number', placeholder: t('countries.taxPercentage') },
      ]}
      FormComponent={DataForm as any}
    />
  );
};

export default CountryFormModal;
