import Dexie, { type EntityTable } from 'dexie';
import { Country } from '../models/countries/country';
import { Customer } from '../models/customers';
import { EventCustomers } from '../models/eventCustomers/eventCustomers';
import { EventProducts } from '../models/eventProducts/eventProducts';
import { EventModel } from '../models/events/events';
import { OrderItem } from '../models/orderItems/orderItem';
import { Order } from '../models/orders/order';
import { Product } from '../models/products/products';
import { ProductType } from '../models/productTypes/productType';
import { StandSector } from '../models/standSectors/standSector';
import { Stand } from '../models/stands/stand';
import { StandType } from '../models/standTypes/StandType';
import { User } from '../models/users';

class OfflineStoreDb extends Dexie {
    countries!: EntityTable<Country, 'id'>;
    customers!: EntityTable<Customer, 'id'>;
    eventCustomers!: EntityTable<EventCustomers, 'id'>;
    eventProducts!: EntityTable<EventProducts, 'id'>;
    events!: EntityTable<EventModel, 'id'>;
    orderItems!: EntityTable<OrderItem, 'id'>;
    orders!: EntityTable<Order, 'id'>;
    products!: EntityTable<Product, 'id'>;
    productTypes!: EntityTable<ProductType, 'id'>;
    standSectors!: EntityTable<StandSector, 'id'>;
    stands!: EntityTable<Stand, 'id'>;
    standTypes!: EntityTable<StandType, 'id'>;
    users!: EntityTable<User, 'id'>;

    constructor() {
        super('offlineStoreDb');
        this.version(+process.env.REACT_APP_CACHE_VERSION_OFFLINE_STORE!).stores({
            countries: 'id',
            customers: 'id, eventId, userId',
            eventCustomers: 'id, eventId, customerId',
            eventProducts: 'id, eventId, productId',
            events: 'id, status, productId',
            orderItems: 'id, orderId, eventId',
            orders: 'id, eventId, standId, customerId',
            products: 'id, eventId',
            productTypes: 'id',
            standSectors: 'id, eventId',
            stands: 'id, eventId, customerId',
            standTypes: 'id',
            users: 'id',
        });
    }
}

const offlineStoreDb = new OfflineStoreDb();

offlineStoreDb.open().catch((err) => {
    console.error('Failed to open db: ' + (err.stack || err));
});

export { offlineStoreDb };
