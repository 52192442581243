import { EntityTable } from "dexie";
import { finishedStatus, statusField } from "../models/staticFields";
import { offlineStoreDb } from "./offlineStoreDb";
const { v4: uuidv4 } = require('uuid');

export enum OfflineStoreKeys {
    COUNTRIES = 'countries',
    CUSTOMERS = 'customers',
    EVENTS = 'events',
    EVENT_PRODUCTS = 'eventProducts',
    EVENT_CUSTOMERS = 'eventCustomers',
    ORDER_ITEMS = 'orderItems',
    ORDERS = 'orders',
    PRODUCTS = 'products',
    PRODUCT_TYPES = 'productTypes',
    STAND_SECTORS = 'standSectors',
    STANDS = 'stands',
    STAND_TYPES = 'standTypes',
    USERS = 'users'
}

export enum RelationKeys {
    STAND_ID = 'standId',
    ORDER_ID = 'orderId',
    USER_ID = 'userId',
    EVENT_ID = 'eventId',
    PRODUCT_ID = 'productId',
    CUSTOMER_ID = 'customerId',
    STAND_SECTOR_ID = 'standSectorId',
    ORDER_ITEM_ID = 'orderItemId',
    PRODUCT_TYPE_ID = 'productTypeId',
    SECTOR_ID = 'sectorId',
    STAND_TYPE_ID = 'standTypeId'
}

export class OfflineUtil {
    private static storeMapping: Record<OfflineStoreKeys, { db: any, storeKey: OfflineStoreKeys, relations: { fk: RelationKeys, table: OfflineStoreKeys }[] }> = {
        [OfflineStoreKeys.COUNTRIES]: {
            db: offlineStoreDb.countries,
            storeKey: OfflineStoreKeys.COUNTRIES,
            relations: []
        },
        [OfflineStoreKeys.CUSTOMERS]: {
            db: offlineStoreDb.customers,
            storeKey: OfflineStoreKeys.CUSTOMERS,
            relations: [
                { fk: RelationKeys.STAND_ID, table: OfflineStoreKeys.STANDS },
                { fk: RelationKeys.ORDER_ID, table: OfflineStoreKeys.ORDERS },
                { fk: RelationKeys.USER_ID, table: OfflineStoreKeys.USERS },
                { fk: RelationKeys.EVENT_ID, table: OfflineStoreKeys.EVENTS }
            ]
        },
        [OfflineStoreKeys.EVENTS]: {
            db: offlineStoreDb.events,
            storeKey: OfflineStoreKeys.EVENTS,
            relations: [
                { fk: RelationKeys.STAND_ID, table: OfflineStoreKeys.STANDS },
                { fk: RelationKeys.ORDER_ID, table: OfflineStoreKeys.ORDERS },
                { fk: RelationKeys.USER_ID, table: OfflineStoreKeys.USERS },
                { fk: RelationKeys.STAND_SECTOR_ID, table: OfflineStoreKeys.STAND_SECTORS },
                { fk: RelationKeys.ORDER_ITEM_ID, table: OfflineStoreKeys.ORDER_ITEMS },
                { fk: RelationKeys.PRODUCT_ID, table: OfflineStoreKeys.PRODUCTS },
                { fk: RelationKeys.CUSTOMER_ID, table: OfflineStoreKeys.CUSTOMERS }
            ]
        },
        [OfflineStoreKeys.EVENT_PRODUCTS]: {
            db: offlineStoreDb.eventProducts,
            storeKey: OfflineStoreKeys.EVENT_PRODUCTS,
            relations: [
                { fk: RelationKeys.EVENT_ID, table: OfflineStoreKeys.EVENTS },
                { fk: RelationKeys.PRODUCT_ID, table: OfflineStoreKeys.PRODUCTS }
            ]
        },
        [OfflineStoreKeys.EVENT_CUSTOMERS]: {
            db: offlineStoreDb.eventCustomers,
            storeKey: OfflineStoreKeys.EVENT_CUSTOMERS,
            relations: [
                { fk: RelationKeys.EVENT_ID, table: OfflineStoreKeys.EVENTS },
                { fk: RelationKeys.CUSTOMER_ID, table: OfflineStoreKeys.CUSTOMERS }
            ]
        },
        [OfflineStoreKeys.ORDER_ITEMS]: {
            db: offlineStoreDb.orderItems,
            storeKey: OfflineStoreKeys.ORDER_ITEMS,
            relations: [
                { fk: RelationKeys.EVENT_ID, table: OfflineStoreKeys.EVENTS },
                { fk: RelationKeys.PRODUCT_ID, table: OfflineStoreKeys.PRODUCTS },
                { fk: RelationKeys.ORDER_ID, table: OfflineStoreKeys.ORDERS }
            ]
        },
        [OfflineStoreKeys.ORDERS]: {
            db: offlineStoreDb.orders,
            storeKey: OfflineStoreKeys.ORDERS,
            relations: [
                { fk: RelationKeys.EVENT_ID, table: OfflineStoreKeys.EVENTS },
                { fk: RelationKeys.STAND_ID, table: OfflineStoreKeys.STANDS },
                { fk: RelationKeys.USER_ID, table: OfflineStoreKeys.USERS },
                { fk: RelationKeys.ORDER_ITEM_ID, table: OfflineStoreKeys.ORDER_ITEMS },
                { fk: RelationKeys.CUSTOMER_ID, table: OfflineStoreKeys.CUSTOMERS }
            ]
        },
        [OfflineStoreKeys.PRODUCTS]: {
            db: offlineStoreDb.products,
            storeKey: OfflineStoreKeys.PRODUCTS,
            relations: [
                { fk: RelationKeys.EVENT_ID, table: OfflineStoreKeys.EVENTS },
                { fk: RelationKeys.PRODUCT_TYPE_ID, table: OfflineStoreKeys.PRODUCT_TYPES },
                { fk: RelationKeys.ORDER_ITEM_ID, table: OfflineStoreKeys.ORDER_ITEMS }
            ]
        },
        [OfflineStoreKeys.PRODUCT_TYPES]: {
            db: offlineStoreDb.productTypes,
            storeKey: OfflineStoreKeys.PRODUCT_TYPES,
            relations: [
                { fk: RelationKeys.PRODUCT_ID, table: OfflineStoreKeys.PRODUCTS }
            ]
        },
        [OfflineStoreKeys.STAND_SECTORS]: {
            db: offlineStoreDb.standSectors,
            storeKey: OfflineStoreKeys.STAND_SECTORS,
            relations: [
                { fk: RelationKeys.EVENT_ID, table: OfflineStoreKeys.EVENTS },
                { fk: RelationKeys.STAND_ID, table: OfflineStoreKeys.STANDS }
            ]
        },
        [OfflineStoreKeys.STANDS]: {
            db: offlineStoreDb.stands,
            storeKey: OfflineStoreKeys.STANDS,
            relations: [
                { fk: RelationKeys.EVENT_ID, table: OfflineStoreKeys.EVENTS },
                { fk: RelationKeys.STAND_TYPE_ID, table: OfflineStoreKeys.STAND_TYPES },
                { fk: RelationKeys.SECTOR_ID, table: OfflineStoreKeys.STAND_SECTORS },
                { fk: RelationKeys.ORDER_ID, table: OfflineStoreKeys.ORDERS },
                { fk: RelationKeys.CUSTOMER_ID, table: OfflineStoreKeys.CUSTOMERS }
            ]
        },
        [OfflineStoreKeys.STAND_TYPES]: {
            db: offlineStoreDb.standTypes,
            storeKey: OfflineStoreKeys.STAND_TYPES,
            relations: [
                { fk: RelationKeys.STAND_ID, table: OfflineStoreKeys.STANDS }
            ]
        },
        [OfflineStoreKeys.USERS]: {
            db: offlineStoreDb.users,
            storeKey: OfflineStoreKeys.USERS,
            relations: [
                { fk: RelationKeys.EVENT_ID, table: OfflineStoreKeys.EVENTS },
                { fk: RelationKeys.CUSTOMER_ID, table: OfflineStoreKeys.CUSTOMERS }
            ]
        }
    };

    static getStore<T>(key: OfflineStoreKeys): { db: any, storeKey: OfflineStoreKeys, relations: { fk: RelationKeys, table: OfflineStoreKeys }[] } {
        const store = OfflineUtil.storeMapping[key];
        if (!store) {
            throw new Error(`Store for key '${key}' not found.`);
        }
        return store;
    }

    static getDb<T>(key: OfflineStoreKeys): any {
        return this.getStore(key).db;
    }

    static getRelations(key: OfflineStoreKeys): { fk: RelationKeys, table: OfflineStoreKeys }[] {
        return this.getStore(key).relations;
    }
}

export function getOfflineDbTable<T>(storeKey: OfflineStoreKeys): EntityTable<T> {
    switch (storeKey) {
        case OfflineStoreKeys.COUNTRIES:
            return offlineStoreDb.countries as unknown as EntityTable<T>;
        case OfflineStoreKeys.CUSTOMERS:
            return offlineStoreDb.customers as unknown as EntityTable<T>;
        case OfflineStoreKeys.EVENT_CUSTOMERS:
            return offlineStoreDb.eventCustomers as unknown as EntityTable<T>;
        case OfflineStoreKeys.EVENT_PRODUCTS:
            return offlineStoreDb.eventProducts as unknown as EntityTable<T>;
        case OfflineStoreKeys.EVENTS:
            return offlineStoreDb.events as unknown as EntityTable<T>;
        case OfflineStoreKeys.ORDER_ITEMS:
            return offlineStoreDb.orderItems as unknown as EntityTable<T>;
        case OfflineStoreKeys.ORDERS:
            return offlineStoreDb.orders as unknown as EntityTable<T>;
        case OfflineStoreKeys.PRODUCTS:
            return offlineStoreDb.products as unknown as EntityTable<T>;
        case OfflineStoreKeys.PRODUCT_TYPES:
            return offlineStoreDb.productTypes as unknown as EntityTable<T>;
        case OfflineStoreKeys.STAND_SECTORS:
            return offlineStoreDb.standSectors as unknown as EntityTable<T>;
        case OfflineStoreKeys.STANDS:
            return offlineStoreDb.stands as unknown as EntityTable<T>;
        case OfflineStoreKeys.STAND_TYPES:
            return offlineStoreDb.standTypes as unknown as EntityTable<T>;
        case OfflineStoreKeys.USERS:
            return offlineStoreDb.users as unknown as EntityTable<T>;
        default:
            throw new Error(`Unsupported store key: ${storeKey}`);
    }
}

export async function offlineJoin<T extends { id: string }>(key1: string, value1: string, key2: string, values2: T[], offlineDbTable: EntityTable<T, "id">) {
    await Promise.all(
        values2.map(async (value2: T) => {
            const compoundId = `${value1}${value2.id}`;
            const offlineEntry = await offlineDbTable.where('id').equals(compoundId).toArray();
            if (offlineEntry.length == 0) {
                try {
                    await offlineDbTable.add({ id: compoundId, [key1]: value1, [key2]: value2.id } as any);
                } catch (error) {
                    console.info(`offlineJoin: Compound id already exists ${compoundId}`)
                }
            }
        })
    );
}

export async function byKeyResults<T extends { id: string }>(items: T[], key: string, value: string, storeKey: OfflineStoreKeys) {
    const offlineDbTable = getOfflineDbTable<T>(storeKey);
    const newList = items.length == 0 ? await offlineDbTable.where(key).equals(value).toArray() : items;

    await Promise.all(
        newList.map(async (item: T) => {
            await upsertOfflineDbTableEntry(item, storeKey);
        })
    );
    return await offlineDbTable.where(key).equals(value).toArray() as any;
}

export async function allResults<T extends { id: string }>(items: T[], storeKey: OfflineStoreKeys) {
    const offlineDbTable = getOfflineDbTable<T>(storeKey);
    const newList = items.length == 0 ? await offlineDbTable.toArray() : items;

    await Promise.all(
        newList.map(async (item: T) => {
            await upsertOfflineDbTableEntry(item, storeKey);
        })
    );

    return await offlineDbTable.toArray() as any;
}

export async function upsertOfflineDbTableEntry<T extends { id: string }>(item: T, storeKey: OfflineStoreKeys) {
    try {
        const offlineDbTable = getOfflineDbTable<T>(storeKey);
        const itemId = item.id;
        if (!itemId) { return; }
        const status = statusField in item ? (item as any)[statusField] : null;

        const offlineStore = await OfflineUtil.getStore(storeKey);
        if (!offlineStore) {
            throw new Error(`No offline store found for store key: ${storeKey}`);
        }

        for (const relation of offlineStore.relations) {
            const foreignKey = relation.fk as keyof T;
            const fkId = item[foreignKey];

            if (fkId) {
                const relatedItem = await OfflineUtil.getDb(relation.table)
                    .where('id')
                    .equals(fkId as any)
                    .first();

                if (relatedItem) {
                    const foreignKeyWithoutId = (foreignKey as string).replace(/Id$/, '');
                    (item as any)[foreignKeyWithoutId] = relatedItem;
                }
            }
        }

        if (status === finishedStatus) {
            if (await offlineDbTable.get(itemId as any)) {
                await offlineDbTable.delete(itemId as any);
            }
            return;
        }

        const existingItem = await offlineDbTable.get(itemId as any);
        if (existingItem) {
            await offlineDbTable.update(itemId as any, item as any);
        } else {
            await offlineDbTable.add(item);
        }
    } catch (error) {
        console.error(`[upsertOfflineDbTableEntry-${storeKey}]`, error);
    }
}

