import { StateCreator } from 'zustand';
import { BaseAgent } from '../../api/interfaces/baseAgent';
import { EntityStore } from './interfaces/entityStore';
import { notifyError, notifySuccess } from '../../components/NotificationProvider';
import { Util } from './util';
import { allResults, getOfflineDbTable, OfflineStoreKeys, upsertOfflineDbTableEntry } from '../../offlinestore/offline.util';

export function createBaseStore<T extends { id: string }>(agent: BaseAgent, storeKey: OfflineStoreKeys): StateCreator<EntityStore<T>> {
    return (set) => ({
        items: Array<T>(),
        selectedItem: null as T | null,
        isLoading: false,
        error: null, 
        storeKey: storeKey,
        fetchItems: async (params?: any) => {
            set({ isLoading: true, error: null });
            try {
                const items = await agent.getAll(params);
                const newList = navigator.onLine ? await allResults(items, storeKey) : await allResults([], storeKey);
                set({ items: navigator.onLine ? items : newList });
            } catch (error) {
                if (navigator.onLine) {
                    notifyError(Util.translate('general.errorOccurred'));
                } else {
                    const offlineDbTable = getOfflineDbTable<T>(storeKey);
                    const offlineItems = await offlineDbTable.toArray();
                    set({ items: offlineItems });
                }
            } finally {
                set({ isLoading: false });
            }
        },
        createItem: async (item: T) => {
            const now = new Date().toJSON();
            try {
                await agent.create(item);
                await upsertOfflineDbTableEntry({ ...item as any, 'createdAt': now, 'updatedAt': now }, storeKey);
                notifySuccess(Util.translate('general.createSuccess'));
                set((state) => ({ items: [...state.items, item] }));
            } catch (error) {
                if (navigator.onLine) {
                    notifyError(Util.translate('general.createFailure'));
                } else {
                    await upsertOfflineDbTableEntry({ ...item as any, 'createdAt': now, 'updatedAt': now }, storeKey);
                    set((state) => ({ items: [...state.items, item] }));
                }
            }
        },
        updateItem: async (id: any, updatedData: Partial<T>) => {
            const now = new Date().toJSON();
            try {
                await agent.update(id, updatedData as any);
                await upsertOfflineDbTableEntry({ ...updatedData, id, 'updatedAt': now } as any, storeKey);
                notifySuccess(Util.translate('general.updateSuccess'));
                set((state) => ({
                    items: state.items.map((item) => item.id === id ? { ...item, ...updatedData } : item)
                }));
            } catch (error) {
                if (navigator.onLine) {
                    notifyError(Util.translate('general.updateFailure'))
                } else {
                    await upsertOfflineDbTableEntry({ ...updatedData, id, 'updatedAt': now } as any, storeKey);
                    set((state) => ({
                        items: state.items.map((item) => item.id === id ? { ...item, ...updatedData } : item)
                    }));
                }
            }
        },
        deleteItem: async (id: any) => {
            const offlineDbTable = getOfflineDbTable<T>(storeKey);

            try {
                await agent.delete(id);
                await offlineDbTable.delete(id);
                notifySuccess(Util.translate('general.deleteSuccess'));

                set((state) => ({ items: state.items.filter(item => item.id !== id) }));
            } catch (error) {
                if (navigator.onLine) {
                    notifyError(Util.translate('general.deleteFailure'));
                } else {
                    await offlineDbTable.delete(id);
                    set((state) => ({ items: state.items.filter(item => item.id !== id) }));
                }
            }
        },
        fetchItemById: async (id: any) => {
            const offlineDbTable = getOfflineDbTable<T>(storeKey);
            let item: T | undefined;
            set({ isLoading: true, error: null });

            try {
                item = navigator.onLine ? await agent.getById(id) : await offlineDbTable.get(id);
                await upsertOfflineDbTableEntry(item as any, storeKey);
            } catch (error) {
                if (navigator.onLine) {
                    notifyError(Util.translate('general.createFailure'));
                } else {
                    item = await offlineDbTable.get(id);
                }
            } finally {
                set({ selectedItem: item ?? null, isLoading: false });
            }
        },
        setItems: (items: Array<T>) => set({ items: items }),
        setSelectedItem: (item: T | null) => set({ selectedItem: item }),
    });
}

export type FilterProps = {
    filters?: any;
    page?: number;
    pageSize?: number;
    orderBy?: string;
    sortDirection?: string;
};