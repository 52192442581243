import React, { useState, useEffect } from 'react';
import { FormikValues } from 'formik';
import DataForm from '../DataForm/DataForm';
import { StandSector } from '../../models/standSectors/standSector';
import useStandSectorStore from '../../store/standSectoreStore';
import { StandSectorFormModalProps } from '../../models/standSectors/standSectorFormModalProps';
const { v4: uuidv4 } = require('uuid');
import { useTranslation } from 'react-i18next';
import ModalUtil from '../../store/core/modalUtil';

const StandSectorFormModal: React.FC<StandSectorFormModalProps> = ({
  initialValues,
  eventId,
}) => {
  const { createItem, updateItem } = useStandSectorStore();
  const { fetchStandSectorsByEventId } = useStandSectorStore();
  const [isEditMode, setIsEditMode] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialValues) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [initialValues]);

  const handleSubmit = async (values: FormikValues, { setSubmitting, resetForm }: any) => {
    try {
      const standSectorData: StandSector = { ...values, eventId, id: isEditMode ? values.id : uuidv4() };

      if (isEditMode && initialValues) {
        await updateItem(values.id!, standSectorData);
      } else {
        await createItem(standSectorData);
      }

      fetchStandSectorsByEventId(eventId, true);
      ModalUtil.closeModal();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <DataForm
      initialValues={initialValues || {
        id: uuidv4(),
        name: '',
        notes: '',
        eventId,
      }}
      onSubmit={handleSubmit}
      fields={[
        { label: t('general.name'), name: 'name', type: 'text', placeholder: t('stand.placeholderName') },
        {
          label: t('general.notes'),
          name: 'notes',
          type: 'textarea',
          placeholder: t('general.placeholderEnterNotes'),
        },
      ]}
      onCancel={ModalUtil.closeModal} />
  );
};

export default StandSectorFormModal;
