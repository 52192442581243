import { create } from 'zustand';
import agent from '../api/agent';
import { Customer } from '../models/customers';
import { createBaseStore } from './util/createBaseStore';
import { EntityStore } from './util/interfaces/entityStore';
import { Stand } from '../models/stands/stand';
import { EventCustomers } from '../models/eventCustomers/eventCustomers';
import { byKeyResults, offlineJoin, OfflineStoreKeys } from '../offlinestore/offline.util';
import { offlineStoreDb } from '../offlinestore/offlineStoreDb';
import { pendingStatus } from '../models/staticFields';
const { v4: uuidv4 } = require('uuid');

interface CustomerStore extends EntityStore<Customer> {
    fetchStandsByCustomerId: (eventId: string) => Promise<void>;
    fetchCustomerByUserId: (userId: string) => Promise<void>;
    fetchCustomersByEvent: (eventId: string, updateStore: boolean) => Promise<void>;
    addCustomerToEvent: (eventId: string, customerId: string) => Promise<void>;
    deleteCustomerFromEvent: (eventId: string, customerId: string) => Promise<void>;
    customerStands: Stand[];
    customersOfSelectedEvent: Customer[];
}

export const useCustomerStore = create<CustomerStore>((set, get) => ({
    ...createBaseStore<Customer>(agent.Customers, OfflineStoreKeys.CUSTOMERS)(set, get, {} as any),
    customersOfSelectedEvent: [],
    customerStands: [],
    fetchStandsByCustomerId: async (customerId: string) => {
        set({ isLoading: true, error: null });

        try {
            const stands = await agent.Stands.getByCustomerId(customerId);
            const offlineStands = await byKeyResults([stands], 'customerId', customerId, OfflineStoreKeys.STANDS);
            set({ customerStands: navigator.onLine ? stands : offlineStands });
        } catch (error) {
            const offlineStands = await byKeyResults([], 'customerId', customerId, OfflineStoreKeys.STANDS);
            set({ customerStands: offlineStands });
        } finally {
            set({ isLoading: false });
        }
    },
    fetchCustomerByUserId: async (userId: string) => {
        set({ isLoading: true, error: null });
        try {
            const customer = await agent.Customers.getCustomerByUserId(userId);
            const offlineCustomer = (await byKeyResults([customer], 'userId', userId, OfflineStoreKeys.CUSTOMERS))[0];
            set({ selectedItem: navigator.onLine ? customer : offlineCustomer });
        } catch {
            const offlineCustomer = (await byKeyResults([], 'userId', userId, OfflineStoreKeys.CUSTOMERS))[0];
            set({ selectedItem: offlineCustomer });
        } finally {
            set({ isLoading: false });
        }
    },
    fetchCustomersByEvent: async (eventId: string, updateStore: boolean) => {
        set({ isLoading: true, error: null });
        try {
            const event = await agent.Events.getById(eventId);
            const customers = await agent.Customers.getByEvent(eventId);
            if (event.status == pendingStatus) await offlineJoin('eventId', eventId, 'customerId', customers, offlineStoreDb.eventCustomers);
            const offlineEventCustomers = await byKeyResults([], 'eventId', eventId, OfflineStoreKeys.EVENT_CUSTOMERS);
            if (updateStore) set({ customersOfSelectedEvent: navigator.onLine ? customers : offlineEventCustomers.map((item: any) => (item as any).customer!) || [] });
        } catch (error) {
            if (eventId) {
                const offlineEventCustomers = await byKeyResults([], 'eventId', eventId, OfflineStoreKeys.EVENT_CUSTOMERS);
                if (updateStore) set({ customersOfSelectedEvent: offlineEventCustomers.map((item: any) => (item as any).customer!) || [] });
            }
        } finally {
            set({ isLoading: false });
        }
    },
    addCustomerToEvent: async (eventId: string, customerId: string) => {
        try {
            await agent.Customers.addToEvent(eventId, customerId);
        } catch (error) {
            // set({ error: i18next.t('general.failure') });
        } finally {
            const offlineEvent = (await byKeyResults([], 'id', eventId, OfflineStoreKeys.EVENTS))[0];
            if (offlineEvent.status == pendingStatus) await offlineStoreDb.eventCustomers.add({ id: `${eventId}${customerId}`, eventId: eventId, customerId: customerId } as EventCustomers);
        }
    },
    deleteCustomerFromEvent: async (eventId: string, customerId: string) => {
        try {
            await agent.Customers.removeFromEvent(eventId, customerId);
        } catch (error) {
            // set({ error: i18next.t('general.failure') });
        } finally {
            const offlineEvent = (await byKeyResults([], 'id', eventId, OfflineStoreKeys.EVENTS))[0];
            if (offlineEvent.status == pendingStatus) await offlineStoreDb.eventCustomers.where('eventId').equals(eventId).and((item: any) => item.customerId == customerId).delete();
        }
    },
}));

export default useCustomerStore;

