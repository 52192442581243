import React, { useEffect } from 'react';
import { Button, Card } from 'antd';
import useCountryStore from '../../store/countryStore';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import { Country } from '../../models/countries/country';
import { useTranslation } from 'react-i18next';
import CountryFormModal from '../../components/Countries/CountryFormModal';
import ModalUtil from '../../store/core/modalUtil';

const CountriesPage: React.FC = () => {
  const { items: items, setSelectedItem, fetchItems, deleteItem } = useCountryStore();
  const { t } = useTranslation();

  const handleEdit = (countryId: string) => {
    const item = items.find((x) => x.id === countryId);

    if (item) {
      setSelectedItem(item);
      ModalUtil.openModal(<CountryFormModal onClose={ModalUtil.closeModal} initialValues={item} />, t('countries.editCountry'), "500px");
    }
  };

  const handleAddCountry = () => {
    ModalUtil.openModal(<CountryFormModal onClose={ModalUtil.closeModal} />, t('countries.createCountry'), "500px");
  };

  const columns: DataTableColumn<Country>[] = [
    { header: t('countries.name'), accessor: 'name' },
    { header: t('countries.countryCode'), accessor: 'countryCode' },
    { header: t('countries.taxPercentage'), accessor: 'taxPercentage' },
  ];

  useEffect(() => {
    fetchItems();
  }, [fetchItems])

  return (
    <>
      <Card
        title={
          <>
            <span style={{ marginRight: '5px' }}>{t('countries.title')}</span>
            <Button type="primary" onClick={handleAddCountry}>
              {t('countries.addCountry')}
            </Button>
          </>
        }
      >
        <DataTable
          columns={columns}
          data={items}
          onDelete={deleteItem}
          onEdit={handleEdit}
          enableFrontendFiltering
        />
      </Card>
    </>
  );
};

export default CountriesPage;
