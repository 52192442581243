import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Tabs, Card, Col, Descriptions, Row, Checkbox, Spin } from 'antd';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import useEventStore from '../../store/eventStore';
import DataTable from '../../components/DataTable/DataTable';
import AddStandModal from '../../components/Stands/AddStandModal';
import StandSectorFormModal from '../../components/StandSectors/StandSectorFormModal';
import { Stand } from '../../models/stands/stand';
import { StandSector } from '../../models/standSectors/standSector';
import useStandSectorStore from '../../store/standSectoreStore';
import DataFormModal from '../../components/DataForm/DataFormModal';
import { Product } from '../../models/products/products';
import { FormikValues } from 'formik';
import DataForm from '../../components/DataForm/DataForm';
import useStandStore from '../../store/standStore';
import { useProductStore } from '../../store/productStore';
import { useTranslation } from 'react-i18next';
import { Util } from '../../store/util/util';
import EventFormModal from '../../components/Events/EventFormModal';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Customer } from '../../models/customers';
import useCustomerStore from '../../store/customerStore';
import ModalUtil from '../../store/core/modalUtil';
import DownloadReportModal from './DownloadReportModal';

const EventDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { selectedItem: selectedEvent, isLoading: isLoadingEvent, fetchItemById: fetchEventById, setSelectedItem } = useEventStore();
  const { eventStands, fetchStandsByEventId, setSelectedItem: setSelectedStand, deleteItem: deleteStand } = useStandStore();
  const { sectorsByEventId, fetchStandSectorsByEventId, deleteItem: deleteStandSector } = useStandSectorStore();
  const { items: products, productsOfSelectedEvent, addProductToEvent, deleteProductFromEvent, fetchItems: fetchProducts, fetchProductsByEvent } = useProductStore();
  const { items: customers, customersOfSelectedEvent, addCustomerToEvent, deleteCustomerFromEvent, fetchItems: fetchCustomers, fetchCustomersByEvent } = useCustomerStore();
  const navigate = useNavigate();
  const eventId = id!;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchEventData = async () => {
      await Promise.all([
        fetchStandsByEventId(eventId, true),
        fetchEventById(eventId),
        fetchStandSectorsByEventId(eventId, true),
        fetchProducts(),
        fetchCustomers(),
        fetchProductsByEvent(eventId, true),
        fetchCustomersByEvent(eventId, true),
      ]);
    };
    fetchEventData();
  }, [eventId, fetchStandsByEventId, fetchEventById, fetchStandSectorsByEventId, fetchProducts, fetchProductsByEvent, fetchCustomers, fetchCustomersByEvent]);

  const handleRowClick = (stand: Stand) => {
    navigate(`/stands/details/${stand.id}`);
  };

  const handleAddSector = () => {
    ModalUtil.openModal(<StandSectorFormModal initialValues={null} eventId={eventId} />,
      t('stand.createStandSector'),
      "50%");
  };

  const handleEditSector = (standSectorId: string) => {
    const newStandSector = sectorsByEventId?.find((s) => s.id === standSectorId);

    if (newStandSector) {
      ModalUtil.openModal(<StandSectorFormModal initialValues={newStandSector} eventId={eventId} />,
        t('stand.editStandSector'),
        "50%");
    }
  };

  const handleAddStand = (customerId: string) => {
    ModalUtil.openModal(<AddStandModal onClose={ModalUtil.closeModal} eventId={eventId} customerId={customerId} />);
  };

  const handleDeleteStand = async (standId: string) => {
    await deleteStand(standId);
    fetchStandsByEventId(eventId, true);
  };

  const handleDeleteSector = async (sectorId: string) => {
    await deleteStandSector(sectorId);
    fetchStandSectorsByEventId(eventId, true);
  };

  const handleAddProductSubmit = async (values: FormikValues) => {
    await addProductToEvent(eventId, values.productId);
    fetchProductsByEvent(eventId, true);

    ModalUtil.openModal(<DataFormModal<{ productId: '' }>
      initialValues={{ productId: '' }}
      onSubmit={handleAddProductSubmit}
      fields={[
        {
          label: t('events.product'),
          name: 'productId',
          type: 'select',
          placeholder: t('events.placeholderProduct'),
          options: products?.map((product: any) => ({
            value: product.id ?? '',
            label: product.name,
          })),
        },
      ]}
      FormComponent={DataForm as any}
    />, t('events.addProduct'))
  };

  const handleEditStand = (id: string, customerId: string) => {
    const stand = eventStands?.find((s) => s.id === id);

    setSelectedStand(stand!);

    ModalUtil.openModal(<AddStandModal
      onClose={ModalUtil.closeModal}
      eventId={selectedEvent?.id!}
      initialValues={stand!}
      customerId={customerId}
    />, t('general.edit'), '500px')
  };

  if (isLoadingEvent) {
    return <Spin>Loading...</Spin>;
  }

  const expandedRowRender = (customer: Customer) => {
    const filteredStandsData = eventStands.filter(x => x.customer?.id === customer.id);

    return <>
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        onClick={() => handleAddStand(customer.id!)}
      >
        {t('events.addStand')}
      </Button>

      <DataTable
        columns={[
          { header: t('general.name'), accessor: 'name', type: 'text' },
          { header: t('stand.sector'), accessor: 'sector.name', type: 'text' },
          { header: t('stand.isDeliveryDone'), accessor: 'isDeliveryDone', type: 'boolean' },
        ]}
        data={filteredStandsData}
        onDelete={handleDeleteStand}
        onEdit={(id) => handleEditStand(id, customer.id!)}
        onRowClick={handleRowClick}
        hidePagination
      />
    </>
  }

  const tabItems = [
    {
      key: '1',
      label: t('events.stands'),
      children: (
        <>
          <DataTable<Stand>
            columns={[
              { header: t('stand.customer'), accessor: 'customer.companyName', type: 'text' },
              { header: t('customer.standName'), accessor: 'name', type: 'text' },
              { header: t('stand.sector'), accessor: 'sector.name', type: 'text' },
              { header: t('stand.isDeliveryDone'), accessor: 'isDeliveryDone', type: 'boolean' },
            ]}
            data={eventStands}
            onRowClick={handleRowClick}
            enableFrontendFiltering={true}
          />
        </>
      ),
    },
    {
      key: '2',
      label: t('general.standOperators'),
      children: (
        <>
          <DataTable<Customer>
            columns={[
              { header: t('customer.companyName'), accessor: 'companyName', type: 'text' },
              { header: t('customer.email'), accessor: 'email', type: 'text' },
              { header: t('customer.phone'), accessor: 'phone', type: 'text' },
              {
                header: t('events.customerOnEvent'), accessor: '', type: 'actions', renderCell: (item: Customer) => {
                  return <Checkbox checked={Boolean(customersOfSelectedEvent?.find(x => x.id === item.id))}
                    onChange={async (e: CheckboxChangeEvent) => {
                      e.preventDefault();
                      if (e.target.checked) {
                        await addCustomerToEvent(eventId, item.id);
                      } else {
                        await deleteCustomerFromEvent(eventId, item.id);
                      }
                      fetchCustomersByEvent(eventId, true);
                    }} />
                }
              },
              {
                header: '', accessor: '', type: 'actions', renderCell: (item: Customer) => {
                  return navigator.onLine && customersOfSelectedEvent?.find(x => x.id === item.id) && <>
                    <Button
                      type="primary"
                      key="downloadInvoice"
                      icon={<DownloadOutlined />}
                      onClick={() => {
                        ModalUtil.openModal(<DownloadReportModal downloadType={1} customer={item} />, t('events.selectStandType'), "50%")
                      }}
                      style={{ margin: '5px 5px' }}
                    >
                      {t('events.downloadInvoice')}
                    </Button >

                    <Button
                      type="primary"
                      key="downloadIssuedArticlesReport"
                      icon={<DownloadOutlined />}
                      onClick={() => {
                        ModalUtil.openModal(<DownloadReportModal downloadType={2} customer={item} />, t('events.selectStandType'), "50%")
                      }}
                      style={{ margin: '5px 5px' }}>
                      {t('events.downloadIssuedArticlesReport')}
                    </Button>
                  </>
                }
              },
            ]}
            data={customers}
            defaultPageSize={100}
            enableFrontendFiltering={true}
            expandedRowRender={expandedRowRender}
          />
        </>
      ),
    },
    {
      key: '3',
      label: t('events.products'),
      children: (
        <>
          <DataTable<Product>
            columns={[
              { header: t('general.name'), accessor: 'name', type: 'text' },
              { header: t('products.shortName'), accessor: 'shortName', type: 'text' },
              {
                header: t('events.productOnEvent'), accessor: '', type: 'actions', renderCell: (item: Product) => {
                  return <Checkbox checked={Boolean(productsOfSelectedEvent?.find(x => x.id === item.id))}
                    onChange={async (e: CheckboxChangeEvent) => {
                      if (e.target.checked) {
                        await addProductToEvent(eventId, item.id);
                      } else {
                        await deleteProductFromEvent(eventId, item.id);
                      }
                      fetchProductsByEvent(eventId, true);
                    }} />
                }
              },
            ]}
            data={products}
            enableFrontendFiltering={true}
            defaultPageSize={100}
          />
        </>
      ),
    },
    {
      key: '4',
      label: t('events.sectors'),
      children: (
        <>
          <Button type="primary" onClick={handleAddSector} style={{ marginBottom: 16 }}>
            {t('events.addStandSector')}
          </Button>
          <DataTable<StandSector>
            columns={[
              { header: t('general.name'), accessor: 'name', type: 'text' },
              { header: t('general.notes'), accessor: 'notes', type: 'text' },
            ]}
            data={sectorsByEventId}
            onEdit={handleEditSector}
            onDelete={handleDeleteSector}
            enableFrontendFiltering={true}
          />
        </>
      ),
    },
  ];

  if (!selectedEvent) {
    return <div>{t('events.notFound')}</div>;
  }

  const handleEdit = () => {
    setSelectedItem(selectedEvent);
    ModalUtil.openModal(<EventFormModal
      initialValues={selectedEvent}
    />,
      selectedEvent ? t('events.editEvent') : t('events.createEvent'),
      "80%");
  };

  return (
    <>
      <Card
        title={`${selectedEvent?.name}`}
        style={{ marginBottom: '20px' }}
        extra={
          <Button type="primary" onClick={handleEdit}>
            {t('general.edit')}
          </Button>
        }
      >
        <Row gutter={16}>
          <Col span={12}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label={t('events.status')}>{selectedEvent.status}</Descriptions.Item>
              <Descriptions.Item label={t('events.startDate')}>{Util.formatDate(selectedEvent.startDate)}</Descriptions.Item>
              <Descriptions.Item label={t('events.endDate')}>{Util.formatDate(selectedEvent.endDate)}</Descriptions.Item>
              <Descriptions.Item label={t('events.eventArea')}>{selectedEvent.eventArea}</Descriptions.Item>
              <Descriptions.Item label={t('events.description')}>{selectedEvent.description}</Descriptions.Item>
              <Descriptions.Item label={t('events.depositPrice')}>{selectedEvent.depositPrice} €</Descriptions.Item>
              <Descriptions.Item label={t('events.depositPriceDishes')}>{selectedEvent.depositPriceDishes} €</Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={12}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label={t('customer.city')}>{selectedEvent.address?.city}</Descriptions.Item>
              <Descriptions.Item label={t('customer.street')}>{selectedEvent.address?.street}</Descriptions.Item>
              <Descriptions.Item label={t('customer.postalCode')}>{selectedEvent.address?.postalCode}</Descriptions.Item>
              <Descriptions.Item label={t('customer.state')}>{selectedEvent.address?.state}</Descriptions.Item>
              <Descriptions.Item label={t('customer.country')}>{selectedEvent.address?.country?.name}</Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={12}>
            <Button
              type='primary'
              key="downloadIssuedArticlesReport"
              icon={<DownloadOutlined />}
              onClick={() => {
                ModalUtil.openModal(<DownloadReportModal downloadType={3} />, t('events.selectStandType'), "50%")
              }} style={{ margin: '10px 5px' }}
            >
              {t('events.downloadIssuedArticlesReportByCustomer')}
            </Button>

            <Button
              type='primary'
              key="downloadIssuedArticlesReportByProduct"
              icon={<DownloadOutlined />}
              onClick={() => {
                ModalUtil.openModal(<DownloadReportModal downloadType={4} />, t('events.selectStandType'), "50%")
              }}
              style={{ margin: '10px 5px' }}
            >
              {t('events.downloadIssuedArticlesReportByProduct')}
            </Button>
          </Col>
        </Row>
      </Card>

      <Tabs items={tabItems} defaultActiveKey="1" />
    </>
  );
};

export default EventDetails;
