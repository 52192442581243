import React from 'react';
import {
  HomeOutlined,
  CoffeeOutlined,
  UserAddOutlined,
  CalendarOutlined,
  UsergroupAddOutlined,
  AppstoreOutlined,
  ToolOutlined,
  GlobalOutlined
} from '@ant-design/icons';
import { ReactNode } from 'react';

export const SidebarData: { title: string; path: string; icon: ReactNode; subNav?: Sidebar[], roles?: string[], showOnOffline: boolean }[] = [
  {
    title: 'sidebar.home',
    path: '/',
    icon: <HomeOutlined />,
    roles: ['admin'],
    showOnOffline: true,
  },
  {
    title: 'sidebar.home',
    path: '/my-profile',
    icon: <HomeOutlined />,
    roles: ['customer'],
    showOnOffline: true,
  },
  {
    title: 'sidebar.products',
    path: '/products',
    icon: <CoffeeOutlined />,
    roles: ['admin'],
    showOnOffline: false,
  },
  {
    title: 'sidebar.customers',
    path: '/customers',
    roles: ['admin'],
    icon: <UserAddOutlined />,
    showOnOffline: false,
  },
  {
    title: 'sidebar.events',
    path: '/events',
    icon: <CalendarOutlined />,
    roles: ['admin'],
    showOnOffline: true,
  },
  {
    title: 'sidebar.users',
    path: '/users',
    icon: <UsergroupAddOutlined />,
    roles: ['admin'],
    showOnOffline: false,
  },
  {
    title: 'sidebar.others',
    path: '/others',
    icon: <AppstoreOutlined />,
    roles: ['admin'],
    showOnOffline: false,
    subNav: [
      {
        title: 'sidebar.otherssub.productTypes',
        path: '/product-types',
        icon: <AppstoreOutlined />,
        roles: ['admin'],
        showOnOffline: false,
      },
      {
        title: 'sidebar.otherssub.standTypes',
        path: '/stand-types',
        icon: <ToolOutlined />,
        roles: ['admin'],
        showOnOffline: false,
      },
      {
        title: 'sidebar.otherssub.countries',
        path: '/countries',
        icon: <GlobalOutlined />,
        roles: ['admin'],
        showOnOffline: false,
      },
    ],
  },
];

export interface Sidebar {
  title: string;
  path: string;
  icon: ReactNode;
  subNav?: Sidebar[];
  roles?: string[];
  showOnOffline: boolean;
}
