import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Button, Modal, Typography, Spin, Checkbox } from 'antd';
import { DeleteOutlined, EditOutlined, DownloadOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { format, isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { ColumnSearchInput } from './ColumnSearchInput';
import { DataType } from './interfaces/dataType';
import { paginationLocales } from './paginationLocals';
import i18n from '../../i18n';

export interface DataTableColumn<T extends object> {
  header: string;
  accessor: keyof T | string;
  type?: 'text' | 'date' | 'actions' | 'currency' | 'number' | 'enum' | 'boolean';
  renderCell?: (item: T) => React.ReactNode;
  hideFilter?: boolean;
}

interface DataTableProps<T extends object> {
  columns: DataTableColumn<T>[];
  data: T[];
  onDelete?: (id: string) => void | Promise<void> | undefined;
  onEdit?: (id: string) => void;
  onRowClick?: (item: T) => void;
  expandedRowRender?: (item: T) => React.ReactNode;
  downloadColumns?: { key: string; displayValue: string }[];
  onDownload?: (id: string, downloadColumn: string) => void;
  defaultOrderByColumn?: string;
  defaultPageSize?: number;
  enableFrontendFiltering?: boolean;
  hidePagination?: boolean;
  defaultExpandAllRows?: boolean;
}

const DataTable = <T extends object>({
  columns,
  data,
  onDelete,
  onEdit,
  onRowClick,
  expandedRowRender,
  downloadColumns,
  onDownload,
  defaultOrderByColumn = "createdAt",
  defaultPageSize = 10,
  enableFrontendFiltering = false,
  hidePagination = false,
  defaultExpandAllRows = false
}: DataTableProps<T>) => {
  const [columnFilters, setColumnFilters] = useState<{ [key: string]: string | number }>({});
  const [filteredData, setFilteredData] = useState<T[]>(data || []);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [orderBy, setOrderBy] = useState(defaultOrderByColumn);
  const [sortDirection, setSortDirection] = useState("asc");
  const [pageSize, setPageSize] = useState(hidePagination ? 1000 : defaultPageSize);
  const [isModalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { Text } = Typography;
  const paginationLocale = paginationLocales[i18n.language] || paginationLocales.en;

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
    setPage(current);
  };

  const debouncedFilter = useMemo(() => debounce((filters: any) => {
    setColumnFilters(filters);
  }, 300), []);

  const handleFilterChange = useCallback((columnKey: string, value: any, dataType: DataType = DataType.TEXT) => {
    const updatedFilters = {
      ...columnFilters,
      [columnKey]: dataType === DataType.NUMBER ? +value : value,
    };
    setColumnFilters(updatedFilters);
    debouncedFilter(updatedFilters);
  }, [columnFilters, debouncedFilter]);

  const handleOrderByChange = useCallback((e: React.MouseEvent, columnKey: string) => {
    e.stopPropagation();
    const newSortDirection =
      orderBy === columnKey && sortDirection === "asc" ? "desc" : "asc";

    setOrderBy(columnKey);
    setSortDirection(newSortDirection);
  }, [orderBy, sortDirection]);

  const formatCell = useCallback((column: DataTableColumn<T>, item: T) => {
    if (column.type === 'date') {
      const dateValue = typeof column.accessor === 'string'
        ? column.accessor.split('.').reduce((acc, key) => (acc as any)[key], item)
        : item[column.accessor];
      const parsedDate = dateValue ? new Date(String(dateValue)) : null;

      return parsedDate && isValid(parsedDate)
        ? format(parsedDate, 'dd.MM.yyyy')
        : '-';
    }

    if (column.type === 'currency') {
      const value = typeof column.accessor === 'string'
        ? column.accessor.split('.').reduce((acc, key) => (acc as any)[key], item)
        : item[column.accessor];

      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(Number(value));
    }

    if (column.type === 'number') {
      const value = typeof column.accessor === 'string'
        ? column.accessor.split('.').reduce((acc, key) => (acc as any)[key], item)
        : item[column.accessor];

      return Number(value);
    }

    if (column.type === 'boolean') {

      const value = typeof column.accessor === 'string'
        ? column.accessor.split('.').reduce((acc, key) => (acc as any)[key], item)
        : item[column.accessor];

      return <Checkbox value={value} checked={value as any} disabled />;
    }

    if (typeof column.accessor === 'string') {
      const keys = column.accessor.split('.');
      let value = item;

      for (const key of keys) {
        const maybeValue = (value as any)?.[key];
        if (maybeValue || maybeValue === 0) {
          value = maybeValue;
        } else {
          value = '-' as any;
        }
      }

      return column.renderCell ? column.renderCell(item) : String(value);
    }

    return column.renderCell ? column.renderCell(item) : String(item[column.accessor]);
  }, [t]);

  const handleRowClick = useCallback((item: T) => {
    if (onRowClick) {
      onRowClick(item);
    }
  }, [onRowClick]);

  const handleDeleteClick = useCallback((event: React.MouseEvent, id: string) => {
    event.stopPropagation();
    setSelectedItemId(id);
    setModalOpen(true);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    if (!navigator.onLine) {
      return;
    }

    if (onDelete && selectedItemId !== null) {
      onDelete(selectedItemId);
    }
    setModalOpen(false);
  }, [onDelete, selectedItemId]);

  const handleCancelDelete = useCallback(() => {
    setSelectedItemId(null);
    setModalOpen(false);
  }, []);

  const handleDownload = useCallback((event: React.MouseEvent, id: string, key: string) => {
    event.stopPropagation();

    if (onDownload) {
      onDownload(id, key);
    }
  }, [onDownload]);

  const handleEditClick = useCallback((event: React.MouseEvent, id: string) => {
    event.stopPropagation();

    if (onEdit) {
      onEdit(id);
    }
  }, [onEdit]);


  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, key) => acc && acc[key], obj);
  };

  const columnsConfig = columns.map((column) => {
    const columnType = column.type || 'text';
    const showFilterAndSorting = ['number', 'text'].includes(columnType) && !column.hideFilter;

    return ({
      title: (
        <>
          <div onClick={(e: React.MouseEvent) => handleOrderByChange(e, column.accessor as string)} style={{ cursor: 'pointer' }}>
            {orderBy === column.accessor && showFilterAndSorting && (
              sortDirection === "asc" ? <CaretUpOutlined /> : <CaretDownOutlined />
            )}
            {column.header}
          </div>
          {(enableFrontendFiltering) && <ColumnSearchInput visible={showFilterAndSorting} column={column} filters={columnFilters || {}} onChange={handleFilterChange} />}
        </>
      ),
      dataIndex: column.accessor as string,
      key: column.accessor as string,
      render: (text: any, record: T) => formatCell(column, record),
    });
  });

  if (onEdit) {
    columnsConfig.push({
      title: '',
      key: 'edit',
      render: (text: any, record: T) => (
        <Button
          icon={<EditOutlined />}
          onClick={(event) => handleEditClick(event, (record as any)?.id)}
          style={{ marginRight: '8px' }}
        />
      ),
      width: 50,
    } as any);
  }

  if (navigator.onLine && onDelete) {
    columnsConfig.push({
      title: '',
      key: 'delete',
      render: (text: any, record: T) => (
        <Button
          icon={<DeleteOutlined />}
          danger
          onClick={(event) => handleDeleteClick(event, (record as any)?.id)}
        />
      ),
      width: 50,
    } as any);
  }

  if (navigator.onLine && onDownload && downloadColumns) {
    columnsConfig.push({
      title: '',
      key: 'download',
      render: (text: any, record: T) => (
        <div>
          {downloadColumns.map((col, index) => (
            <Button
              key={col.key}
              icon={<DownloadOutlined />}
              onClick={(event) => handleDownload(event, (record as any)?.id, col.key)}
              style={{ marginRight: '8px', ...(index !== 0 && { marginTop: '5px' }) }}
            >
              {col.displayValue}
            </Button>
          ))}
        </div>
      ),
      width: 150,
    } as any);
  }

  useEffect(() => {
    setLoading(true);
    let filtered = data;

    Object.keys(columnFilters).forEach((key) => {
      const filterValue = columnFilters[key];
      filtered = filtered.filter((item) => {
        const itemValue = getNestedValue(item, key);
        if (typeof filterValue == 'number') {
          return itemValue == filterValue;
        }
        return String(itemValue).toLowerCase().includes(String(filterValue).toLowerCase());
      });
    });

    if (orderBy) {
      filtered.sort((a, b) => {
        const valueA = getNestedValue(a, orderBy);
        const valueB = getNestedValue(b, orderBy);
        if (valueA === valueB) return 0;
        const directionMultiplier = sortDirection === "asc" ? 1 : -1;
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB) * directionMultiplier;
        }

        return (valueA > valueB ? 1 : -1) * directionMultiplier;
      });
    }

    setFilteredData(filtered);
    setLoading(false);

  }, [columnFilters, data, enableFrontendFiltering, orderBy, sortDirection]);

  return (
    <>
      {loading ? (
        <Spin />
      ) : (<Table
        columns={columnsConfig}
        dataSource={filteredData}
        rowKey={(record: T) => (record as any)?.id}
        expandable={expandedRowRender ? { expandedRowRender, defaultExpandAllRows: defaultExpandAllRows } : undefined}
        pagination={{
          showTotal: (total: number, [start, end]: [number, number]) => t('general.tableTotalRow', { start, end, total }),
          onShowSizeChange: handlePageSizeChange,
          pageSizeOptions: [1, 10, 20, 50, 100],
          defaultPageSize: defaultPageSize,
          onChange: handlePageChange,
          total: filteredData.length,
          showSizeChanger: true,
          current: page,
          pageSize,
          hideOnSinglePage: hidePagination,
          locale: paginationLocale,
        }}
        onRow={(record: T) => ({
          onClick: () => handleRowClick(record),
          style: { cursor: 'pointer' }
        })}
        bordered
      />)}

      <Modal
        title={t('general.confirmDeleteTitle')}
        open={isModalOpen}
        onOk={handleConfirmDelete}
        onCancel={handleCancelDelete}
        okText={t('general.yes')}
        cancelText={t('general.no')}
      >
        <Text>{t('general.confirmDeleteMessage')}</Text>
      </Modal>
    </>
  );
};

export default DataTable;
