import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Descriptions, Alert, Typography, Row, Col, Button } from 'antd';
import useOrderStore from '../../store/orderStore';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import { OrderItem } from '../../models/orderItems/orderItem';
import { notifyError } from '../../components/NotificationProvider';
import { useTranslation } from 'react-i18next';

const OrderDetailsScreen: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { selectedItem: order, fetchItemById, downloadPDFByBillType } = useOrderStore();
    const { Title } = Typography;
    const { t } = useTranslation();

    useEffect(() => {
        if (!order || order.id !== id) {
            fetchItemById(id!);
        }
    }, [id, order, fetchItemById]);

    if (!order) {
        return <Alert message={t('order.notFound')} type="warning" showIcon />;
    }

    const orderItemColumns: DataTableColumn<OrderItem>[] = [
        { header: t('order.productName'), accessor: 'product.name' },
        { header: t('order.quantity'), accessor: 'issuedItems.totalQuantity' },
        { header: t('products.serviceCostPrice'), accessor: 'serviceCostPrice', type: 'currency' },
        { header: t('order.totalPrice'), accessor: 'totalPrice', type: 'currency' },
    ];

    if (order.stand) {
        orderItemColumns.push(
            { header: t('order.totalUsed'), accessor: 'usedItems.totalQuantity' },
            { header: t('order.totalUnused'), accessor: 'unusedItems.totalQuantity' },
            { header: t('order.totalClean'), accessor: 'cleanItems.totalQuantity' }
        );
    }

    const formatAddress = (address: any) => {
        if (!address) return '-';

        return (
            <>
                {address.firstName} {address.lastName}, {address.companyName}<br />
                {address.street}, {address.addressLine2 && `${address.addressLine2}, `}
                {address.city}, {address.state} {address.postalCode}
            </>
        );
    };

    const handleDownloadPDF = async (key: string) => {
        try {
            await downloadPDFByBillType(order.id, key);
        } catch (error) {
            notifyError(`${t('general.errorDownloadingPDF')}: ${error}`);
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <Card title={`Order ${order.orderNumber}`} style={{ marginBottom: '20px' }} >
                <Row gutter={16}>
                    <Col span={12}>
                        <Title level={5}>{t('order.customerInfor')}</Title>
                        <Descriptions bordered column={1}>
                            <Descriptions.Item label={t('customer.firstName')}>{order.customer.firstName}</Descriptions.Item>
                            <Descriptions.Item label={t('customer.lastName')}>{order.customer.lastName}</Descriptions.Item>
                            <Descriptions.Item label={t('customer.companyName')}>{order.customer.companyName}</Descriptions.Item>
                            <Descriptions.Item label={t('customer.phone')}>{order.customer.phone}</Descriptions.Item>
                            <Descriptions.Item label={t('customer.email')}>{order.customer.email}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={12}>
                        <Title level={5}>{t('order.info')}</Title>
                        <Descriptions bordered column={1}>
                            <Descriptions.Item label={t('order.number')}>{order.orderNumber}</Descriptions.Item>
                            <Descriptions.Item label={t('order.date')}>{new Date(order.date).toLocaleDateString()}</Descriptions.Item>
                            {/* <Descriptions.Item label={t('order.totalPrice')}>{(+order?.totalPrice)?.toFixed(2)} €</Descriptions.Item>
                            <Descriptions.Item label={t('order.status')}>{order.status}</Descriptions.Item> */}
                            <Descriptions.Item label={t('general.notes')}>{order.notes || '-'}</Descriptions.Item>
                        </Descriptions>
                    </Col>

                </Row>

                <Row gutter={16} style={{ marginTop: '20px' }}>
                    {order.shippingAddress && <Col span={12}>
                        <Title level={5}>{t('order.shippingAddress')}</Title>
                        <Descriptions bordered column={1}>
                            <Descriptions.Item>{formatAddress(order.shippingAddress)}</Descriptions.Item>
                        </Descriptions>
                    </Col>}

                    {order.billingAddress && <Col span={12}>
                        <Title level={5}>{t('order.billingAddress')}</Title>
                        <Descriptions bordered column={1}>
                            <Descriptions.Item>{formatAddress(order.billingAddress)}</Descriptions.Item>
                        </Descriptions>
                    </Col>}
                </Row>

                {order.stand && <Row gutter={16} style={{ marginTop: '20px' }}>
                    <Col span={12}>
                        <Title level={5}>{t('order.standInfo')}</Title>
                        <Descriptions bordered column={1}>
                            <Descriptions.Item label={t('order.standName')}>{order.stand.name}</Descriptions.Item>
                            <Descriptions.Item label={t('order.standEmail')}>{order.stand.email || '-'}</Descriptions.Item>
                            <Descriptions.Item label={t('order.standEvent')}>{order.stand.event?.name || '-'}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>}

                <Row gutter={16} style={{ marginTop: '20px' }}>
                    <Col>
                        <Button type="primary" onClick={() => handleDownloadPDF('deliverynote')}>
                            {t('order.downloadDeliveryBill')}
                        </Button>
                        <Button type="primary" onClick={() => handleDownloadPDF('redemptionslip')} style={{ marginLeft: '10px' }}>
                            {t('order.downloadRedemptionSlip')}
                        </Button>
                    </Col>
                </Row>
            </Card>

            <Card style={{ marginTop: '20px' }}>
                <Title level={4}>{t('order.orderItems')}</Title>
                <DataTable columns={orderItemColumns} data={order.orderItems} enableFrontendFiltering hidePagination />
            </Card>
        </div>
    );
};

export default OrderDetailsScreen;
